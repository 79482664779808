import React from 'react';
import Navbar from '../components/NavigationBar'; // Asegúrate de importar correctamente

const MainLayout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Navbar />
      <main className="flex-1 container mx-auto p-4 mt-16"> {/* Ajuste para el margen superior */}
        {children}
      </main>
      {/* Footer */}
      <footer className="bg-gray-800 text-white py-6 w-full mt-8">
        <div className="max-w-4xl mx-auto flex flex-col items-center">
          {/* <img src={logo} alt="TodoHub Logo" className="h-12 mb-4" /> Muestra el logo */}
          <p className="text-sm mb-2">© 2024 TodoHub.io - Todos los derechos reservados.</p>
        </div>
      </footer>
    </div>
  );
};

export default MainLayout;