import React, { useState } from 'react';

const ModalMessage = ({ onClose, onContinue }) => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleContinue = () => {
    onContinue(subject, message); // Enviar los datos al componente PostCard
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">Enviar Mensaje</h2>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="subject">Asunto:</label>
          <input
            type="text"
            id="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="message">Mensaje:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg"
          />
        </div>
        <div className="flex justify-end space-x-2">
          <button onClick={onClose} className="px-4 py-2 bg-gray-300 rounded-lg">Cancelar</button>
          <button onClick={handleContinue} className="px-4 py-2 bg-blue-500 text-white rounded-lg">Continuar</button>
        </div>
      </div>
    </div>
  );
};

export default ModalMessage;