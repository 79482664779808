import React, { useContext } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { SelectedUserContext } from '../../utils/AuthContext'; // Asegúrate de importar el contexto


const BarraSuperior = ({ handleBackToUsers }) => {
  // Obtener el nombre del usuario seleccionado desde el contexto
  const { selectedUserName } = useContext(SelectedUserContext); // Asegúrate de que el contexto esté disponible

  return (
    <div className="flex items-center justify-between p-4 bg-white border-b">
      <div className="flex items-center space-x-3">
        {/* Botón de retroceso en móvil */}
        <button className="md:hidden p-2 text-gray-500 focus:outline-none" onClick={handleBackToUsers}>
          <ArrowLeftIcon className="w-6 h-6" />
        </button>
        {/* Mostrar el nombre del usuario seleccionado o el texto por defecto */}
        <h2 className="text-lg font-semibold">{selectedUserName || 'Selecciona un chat'}</h2>
      </div>
      <p className="text-sm text-gray-500">Última conexión: Hace 1 día</p>
    </div>
  );
};

export default BarraSuperior;
