import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../utils/AuthContext';


const Navbar = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // Estado para controlar el menú móvil

  const handleLogout = () => {
    logout();
    navigate('/logout_user');
  };

  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-white hover:text-gray-300">
          <h1 className="text-white text-2xl font-bold">TRABALU.COM</h1>
        </Link>
        <div className="flex items-center space-x-4">
          {/* Botón para el menú móvil */}
          <button
            className="block lg:hidden text-white"
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
              />
            </svg>
          </button>

          {/* Menú para pantallas grandes */}
          <div className="hidden lg:flex space-x-4 items-center">
            <Link to="/" className="text-white hover:text-gray-300">
              Home
            </Link>

            {currentUser ? (
              <>
                <Link to="/publicaciones" className="text-white hover:text-gray-300">
                  Publicaciones
                </Link>
                <Link to="/crear_cards" className="text-white hover:text-gray-300">
                  Crear Cards
                </Link>
                <Link to="/user_posts" className="text-white hover:text-gray-300">
                  Mis Publicaciones
                </Link>
                <Link to="/main_chat" className="text-white hover:text-gray-300">
                  Chat
                </Link>
                <Link to="/testing" className="text-white hover:text-gray-300">
                  Testing
                </Link>
                <Link to="/profile" className="text-white hover:text-gray-300">
                  Profile
                </Link>
                <Link to="#" className="text-white hover:text-gray-300" onClick={handleLogout}>
                  Logout
                </Link>
              </>
            ) : (
              <>
                <Link to="/login_user" className="text-white hover:text-gray-300">
                  Login
                </Link>
                <Link to="/register_user" className="text-white hover:text-gray-300">
                  Register
                </Link>
              </>
            )}
          </div>

          {/* Componente de notificaciones */}
          <div className="relative">

          </div>
        </div>
      </div>

      {/* Menú móvil */}
      {isOpen && (
        <div className="lg:hidden bg-gray-800">
          <Link to="/" className="block text-white hover:text-gray-300 p-2">
            Home
          </Link>

          {currentUser ? (
            <>
              <Link to="/publicaciones" className="block text-white hover:text-gray-300 p-2">
                Publicaciones
              </Link>
              <Link to="/crear_cards" className="block text-white hover:text-gray-300 p-2">
                Crear Cards
              </Link>
              <Link to="/user_posts" className="block text-white hover:text-gray-300 p-2">
                Mis Publicaciones
              </Link>
              <Link to="/main_chat" className="block text-white hover:text-gray-300 p-2">
                Chat
              </Link>
              <Link to="/testing" className="block text-white hover:text-gray-300 p-2">
                Testing
              </Link>
              <Link to="/profile" className="block text-white hover:text-gray-300 p-2">
                Profile
              </Link>
              <Link to="#" className="block text-white hover:text-gray-300 p-2" onClick={handleLogout}>
                Logout
              </Link>
              <Link to="/reset-password/5" className="text-white hover:text-gray-300">
                reset-password
              </Link>
            </>
          ) : (
            <>
              <Link to="/login_user" className="block text-white hover:text-gray-300 p-2">
                Login
              </Link>
              <Link to="/register_user" className="block text-white hover:text-gray-300 p-2">
                Register
              </Link>
            </>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;