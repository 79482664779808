import React, { useEffect, useState } from 'react';
import ObjCard from './PostCard';
import ApiAxios from '../utils/ApiClient/PostApiClient';
import PostAcceptanceService from '../utils/ApiClient/PostAcceptanceApiClient';
import { Link } from 'react-router-dom';

const CardList = () => {
  const [cardsData, setCardsData] = useState([]);
  const [appliedPostIds, setAppliedPostIds] = useState([]);
  const [errors, setErrors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Estado para la página actual
  const [totalPages, setTotalPages] = useState(1); // Estado para las páginas totales

  useEffect(() => {
    const fetchData = async (page = 1) => {
      try {
        const response = await ApiAxios.fetchPostsByPage(page);
        if (response.posts) {
          setCardsData(response.posts);
          setTotalPages(response.total_pages);
        }

        const appliedIds = await PostAcceptanceService.getAllPostApplied();
        setAppliedPostIds(appliedIds);
      } catch (error) {
        console.error("Error al obtener publicaciones:", error);
        setErrors(error.response ? error.response.data.errors : ["Unknown error occurred"]);
      }
    };
    fetchData(currentPage); // Llama a la API cuando cambie la página
  }, [currentPage]);

  const handleApply = async (postId) => {
    try {
      await PostAcceptanceService.create({ post_id: postId });
      setAppliedPostIds([...appliedPostIds, postId]);
    } catch (error) {
      console.error(`Error al aplicar a la publicación con ID: ${postId}`, error);
    }
  };

  const handleRemoveApplication = async (postId) => {
    try {
      const response = await PostAcceptanceService.fetchAccepted(postId);
      const acceptance = response.find(acceptance => acceptance.post_id === postId);

      if (acceptance) {
        await PostAcceptanceService.delete(acceptance.id);
        setAppliedPostIds(appliedPostIds.filter(id => id !== postId));
      }
    } catch (error) {
      console.error(`Error al eliminar la aplicación de la publicación con ID: ${postId}`, error);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-extrabold text-gray-800">Proyectos Disponibles</h2>
        <Link to="/crear_cards">
          <button className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white px-6 py-3 rounded-full shadow-lg hover:shadow-xl hover:bg-indigo-600 transition-transform transform hover:scale-105 focus:outline-none focus:ring focus:ring-indigo-300">
            + Crear Post
          </button>
        </Link>
      </div>

      {errors.length > 0 && (
        <div className="bg-red-200 text-red-600 p-4 rounded-md">
          {errors.map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {cardsData.length > 0 ? (
          cardsData.map((card, index) => (
            <ObjCard
              key={index}
              title={card.title}
              date={new Date(card.created_at).toLocaleDateString()}
              status={card.active ? "Activo" : "Inactivo"}
              skills={card.skills.split(', ')}
              salary={`$${parseFloat(card.salary).toLocaleString()}`}
              postId={card.id}
              isApplied={appliedPostIds.includes(card.id)}
              onApply={handleApply}
              onRemoveApplication={handleRemoveApplication}
            />
          ))
        ) : (
          <p>No hay datos disponibles</p>
        )}
      </div>

      <div className="flex justify-between mt-6">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
        >
          Anterior
        </button>
        <span>Página {currentPage} de {totalPages}</span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r"
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default CardList;
