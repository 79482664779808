import axiosInstance from '../AxiosConfig'; // Importa la configuración de axios

const UserService = {
  // Registrar un nuevo usuario (Sign Up)
  async registerUser(userData) {
    try {
      // Espera la resolución de la promesa de axiosInstance.post
      const response = await axiosInstance.post('/users', { user: userData });

      // Registra la respuesta completa en la consola
      console.log('Respuesta completa del servidor:', response);

      // Retorna la respuesta completa o los datos que necesitas
      return response.data; // Aquí puedes retornar solo los datos si es lo que necesitas
    } catch (error) {
      console.error('Error al registrar el usuario:', error);
      throw error; // Lanza el error para ser manejado por quien llame a esta función
    }
  },

  // Iniciar sesión (Sign In)
  async loginUser(userData) {
    try {
      const response = await axiosInstance.post('/users/sign_in', { user: userData });
      return response.data;
    } catch (error) {
      console.error('Error al iniciar sesión del usuario:', error);
      throw error;
    }
  },

  // Cerrar sesión (Sign Out)
  async logoutUser() {
    try {
      const response = await axiosInstance.delete('/users/sign_out');
      return response.data;
    } catch (error) {
      console.error('Error al cerrar sesión del usuario:', error);
      throw error;
    }
  },

  // Obtener todos los usuarios (Read All)
  async fetchAllUsers() {
    try {
      const response = await axiosInstance.get('/users');
      return response.data;
    } catch (error) {
      console.error('Error al obtener los usuarios:', error);
      throw error;
    }
  },

  // Verificar si un usuario está activo (Is Active)
  async getInfoCurrentUser() {
    try {
      const response = await axiosInstance.get('/is_active'); // Ajusta la ruta según tu API
      console.log('Respuesta del servidor:', response); // Log de la respuesta completa
  
      if (response.status === 200 && response.data) {
        console.log('Información del usuario actual:', response.data);
        return response.data; // Devuelve los datos del usuario actual
      } else {
        console.error('Error: Respuesta inesperada del servidor', response);
        throw new Error('Respuesta inesperada del servidor');
      }
    } catch (error) {
      if (error.response) {
        // El servidor respondió con un código de estado que no está en el rango de 2xx
        console.log('El usuario no extiste:', error.response);
      }
      throw error;
    }
  },

  // Obtener un usuario específico por ID (Read One)
  async fetchUserProfileById(userId) {
    try {
      const response = await axiosInstance.get(`/user_profiles/${userId}`);
      return response.data;
    } catch (error) {
      console.error('Error al obtener el perfil del usuario por ID:', error);
      throw error;
    }
  },

  // Actualizar un usuario existente (Update)
  async updateUser(userId, updatedData) {
    try {
      const response = await axiosInstance.put(`/users/${userId}`, { user: updatedData });
      return response.data;
    } catch (error) {
      console.error('Error al actualizar el usuario:', error);
      throw error;
    }
  },

  // Eliminar un usuario (Delete)
  async deleteUser(userId) {
    try {
      const response = await axiosInstance.delete(`/users/${userId}`);
      return response.data;
    } catch (error) {
      console.error('Error al eliminar el usuario:', error);
      throw error;
    }
  }
};

// Exporta el objeto UserService para usar en otras partes de la aplicación
export default UserService;