import axiosInstance from '../AxiosConfig'; // Importa la configuración de axios

const UserProfileService = {
  // Obtener el perfil del usuario actual
  getCurrentProfile() {
    return axiosInstance.get('user_profiles/getCurrentProfile')
      .then(response => {
        if (response && response.data) {
          console.log("Este es el response de getCurrentProfile");
          console.log(response);
          return response.data.user_profile;
        } else {
          throw new Error('No se recibió respuesta válida del servidor');
        }
      })
      .catch(error => {
        // Mostrar el error específico si existe, de lo contrario, un mensaje genérico
        const errorMessage = error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Error al obtener el perfil de usuario. Inténtalo de nuevo más tarde.';
        console.error(errorMessage);
        throw new Error(errorMessage);
      });
  },
  // Crear un perfil de usuario (Create)
  createUserProfile(profileData) {
    return axiosInstance.post('/user_profiles', { user_profile: profileData })
      .then(response => response.data)
      .catch(error => {
        console.error('Error al crear el perfil de usuario:', error);
        throw error;
      });
  },

  // Obtener todos los perfiles de usuario (Read All)
  fetchAllUserProfiles() {
    return axiosInstance.get('/user_profiles')
      .then(response => response.data)
      .catch(error => {
        console.error('Error al obtener los perfiles de usuario:', error);
        throw error;
      });
  },

  // Obtener un perfil de usuario específico por ID (Read One)
  fetchUserProfileById(profileId) {
    return axiosInstance.get(`/user_profiles/${profileId}`)
      .then(response => response.data)
      .catch(error => {
        console.error(`Error al obtener el perfil de usuario con ID ${profileId}:`, error);
        throw error;
      });
  },

  // Actualizar un perfil de usuario existente (Update)
  updateUserProfile(profileId, updatedData) {
    return axiosInstance.put(`/user_profiles/${profileId}`, { user_profile: updatedData })
      .then(response => response.data)
      .catch(error => {
        console.error('Error al actualizar el perfil de usuario:', error);
        throw error;
      });
  },

  // Eliminar un perfil de usuario (Delete)
  deleteUserProfile(profileId) {
    return axiosInstance.delete(`/user_profiles/${profileId}`)
      .then(response => response.data)
      .catch(error => {
        console.error('Error al eliminar el perfil de usuario:', error);
        throw error;
      });
  }
};

window.UserProfileService = UserProfileService;
// Exporta el objeto UserProfileService para usar en otras partes de la aplicación
export default UserProfileService;

