import axiosInstance from '../AxiosConfig';

const UserNotification = {
  // Obtener todas las notificaciones
  getNotifications() {
    return axiosInstance.get('/notifications');
  },

  // Crear una nueva notificación
  createNotification(notificationData) {
    return axiosInstance.post('/notifications', notificationData);
  },

  // Obtener una notificación específica por ID
  getNotificationById(id) {
    return axiosInstance.get(`/notifications/${id}`);
  },

  // Actualizar una notificación específica por ID
  updateNotification(id, updatedData) {
    return axiosInstance.patch(`/notifications/${id}`, updatedData);
  },

  // Marcar una notificación como leída por ID
  markAsRead(id) {
    return axiosInstance.patch(`/notifications/${id}/mark_as_read`);
  },

  obtener_all() {
    return axiosInstance.get(`/notifications/obtener_all`);
  },



  // Eliminar una notificación específica por ID
  deleteNotification(id) {
    return axiosInstance.delete(`/notifications/${id}`);
  }
};

export default UserNotification;