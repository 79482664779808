import axiosInstance from '../AxiosConfig'; // Importa la configuración de axios

const ResetPassword = {
  // Método para enviar el correo de restablecimiento de contraseña
  async sendResetPasswordEmail(email) {
    try {
      const response = await axiosInstance.post('/users/password', {
        email: email,  // Envía el correo electrónico
      });
      return response.data;  // Retorna los datos de la respuesta
    } catch (error) {
      // Manejo del error
      if (error.response) {
        throw new Error(error.response.data.error);
      } else {
        throw new Error('Ocurrió un error al intentar enviar el correo.');
      }
    }
  },

  // Método para actualizar la contraseña con el token
  async updateResetPassword({ token, password, password_confirmation }) {
    try {
      const response = await axiosInstance.put('/users/password', {
        user: {
          reset_password_token: token,  // El token capturado de la URL
          password,  // Nueva contraseña
          password_confirmation,  // Confirmación de la contraseña
        },
      });
      return response.data;  // Retorna los datos de la respuesta
    } catch (error) {
      // Manejo del error
      console.error('Error al restablecer la contraseña desde el cliente:', error);
      if (error.response) {
        throw new Error(error.response.data.error);  // Propaga el error desde la API
      } else {
        throw new Error('Ocurrió un error al intentar restablecer la contraseña.');
      }
    }
  }
};

export default ResetPassword;
