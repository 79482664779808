import cableService from '../CableConnection'; // Asegúrate de que cableService esté correctamente configurado

class ActionCableMessages {
  constructor() {
    this.subscriptions = {}; // Almacena las suscripciones activas por chatroom
    this.isConnected = false; // Estado de la conexión WebSocket
  }

  // Inicializa el WebSocket solo si no está inicializado
  initializeWebSocket() {
    try {
      if (!this.isConnected) {
        console.log('[ActionCable] Initializing WebSocket connection...');
        cableService.initialize();
        this.isConnected = true;
      } else {
        console.log('[ActionCable] WebSocket connection already established.');
      }
    } catch (error) {
      console.error('[ActionCable] Error initializing WebSocket:', error);
    }
  }

  // Desconectar el WebSocket solo si no hay suscripciones activas
  disconnectWebSocket() {
    try {
      if (Object.keys(this.subscriptions).length === 0 && this.isConnected) {
        console.log('[ActionCable] No active subscriptions, disconnecting WebSocket...');
        cableService.disconnect();
        this.isConnected = false;
      } else {
        console.log('[ActionCable] Active subscriptions found, WebSocket remains open.');
      }
    } catch (error) {
      console.error('[ActionCable] Error disconnecting WebSocket:', error);
    }
  }

  // Función para recibir mensajes en tiempo real en un chatroom específico
  subscribeToMessagesChannel(chatroomId, onMessageReceived) {
    try {
      this.initializeWebSocket();

      const consumer = cableService.getConsumer();
      if (!consumer) {
        console.error('[ActionCable] WebSocket consumer not initialized.');
        return;
      }

      if (this.subscriptions[chatroomId]) {
        console.warn(`[ActionCable] Already subscribed to chatroom ${chatroomId}.`);
        return;
      }

      // Crear la suscripción para el chatroom
      const subscription = consumer.subscriptions.create(
        { channel: 'MessagesChannel', chatroom_id: chatroomId },
        {
          received: (data) => {
            console.log(`[ActionCable] New message received in chatroom ${chatroomId}:`, data);
            if (typeof onMessageReceived === 'function') {
              onMessageReceived(data);
            }
          },
          connected: () => {
            console.log(`[ActionCable] Connected to MessagesChannel for chatroom ${chatroomId}.`);
          },
          disconnected: () => {
            console.log(`[ActionCable] Disconnected from MessagesChannel for chatroom ${chatroomId}.`);
          },
          rejected: () => {
            console.error(`[ActionCable] Subscription to MessagesChannel for chatroom ${chatroomId} was rejected.`);
          }
        }
      );

      this.subscriptions[chatroomId] = subscription;
      console.log(`[ActionCable] Subscribed to chatroom ${chatroomId}.`);
    } catch (error) {
      console.error(`[ActionCable] Error subscribing to chatroom ${chatroomId}:`, error);
    }
  }

  // Función para desuscribirse de un canal de mensajes
  unsubscribeFromMessagesChannel(chatroomId) {
    try {
      const subscription = this.subscriptions[chatroomId];
      if (subscription) {
        subscription.unsubscribe();
        delete this.subscriptions[chatroomId];
        console.log(`[ActionCable] Unsubscribed from chatroom ${chatroomId}.`);

        // Desconectar WebSocket si no hay más suscripciones activas
        this.disconnectWebSocket();
      } else {
        console.warn(`[ActionCable] No active subscription found for chatroom ${chatroomId}.`);
      }
    } catch (error) {
      console.error(`[ActionCable] Error unsubscribing from chatroom ${chatroomId}:`, error);
    }
  }
}

// Exportar una única instancia de ActionCableMessages (Singleton)
const actionCableMessages = new ActionCableMessages();
export default actionCableMessages;