import React, { useState, useEffect, useContext } from 'react';
import {
  PencilSquareIcon,
  StarIcon,
  XMarkIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  ChatBubbleLeftIcon,
  TrashIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/solid';
import Modal from '../../components/ModalWindow'; // Asegúrate de que la ruta del modal sea correcta
import UserProfileApiClient from '../../utils/ApiClient/UserProfileApiClient';
import whiteBackgroundImage from '../../assets/Images/sinFondo.png';
import { AuthContext } from '../../utils/AuthContext';

const Profile = () => {
  const { currentUser} = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasLiked, setHasLiked] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [likeCount, setLikeCount] = useState(0); // Simulación de cantidad de likes
  const [profile, setProfile] = useState({
    profile_image: '',
    background_image: '',
    full_name: '',
    email: '',
    phone_number: '',
    date_of_birth: '',
    country: '',
    user_verificated: true,
    last_login: '',
    notification_email: true,
    skills: [],
    user_stars: 0,
    user_account: '',
    user_id: currentUser.id, // Usar el ID del usuario actual
    posts_count: 0,
    bio: '',
    website_url: '',
    social_links: '',
    location: '',
    job_title: '',
    company: '',
    education: '',
    languages: [],
    interests: [],
    followers_count: 0,
    following_count: 0,
  });
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    UserProfileApiClient.getCurrentProfile()
      .then((userProfile) => {
        console.log('Perfil del usuario:', userProfile); // Mostrar el perfil del usuario en la consola

        if (userProfile) {
          setProfile({
            profile_image: userProfile.profile_image || '',
            background_image: userProfile.background_image || '',
            full_name: userProfile.full_name || '',
            email: userProfile.email || '',
            phone_number: userProfile.phone_number || '',
            date_of_birth: userProfile.date_of_birth || '',
            country: userProfile.country || '',
            user_verificated: userProfile.user_verificated,
            last_login: userProfile.last_login || '',
            notification_email: userProfile.notification_email,
            skills: userProfile.skills || [],
            user_stars: userProfile.user_stars || 0,
            user_account: userProfile.user_account || '',
            user_id: userProfile.user_id,
            posts_count: userProfile.posts_count || 0,
            bio: userProfile.bio || '',
            website_url: userProfile.website_url || '',
            social_links: userProfile.social_links || '',
            location: userProfile.location || '',
            job_title: userProfile.job_title || '',
            company: userProfile.company || '',
            education: userProfile.education || [],
            languages: userProfile.languages || [],
            interests: userProfile.interests || [],
            followers_count: userProfile.followers_count || 0,
            following_count: userProfile.following_count || 0,
          });
        } else {
          console.log("No se encontraron datos de perfil para el usuario actual.");
        }
      })
      .catch((error) => {
        console.error("Error al obtener el perfil del usuario actual:", error);
      });
  }, [currentUser.id]);

  const handleAddSkill = (event) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      setProfile((prevProfile) => ({
        ...prevProfile,
        skills: [...prevProfile.skills, inputValue.trim()],
      }));
      setInputValue('');
    }
  };

  const handleRemoveSkill = (indexToRemove) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      skills: prevProfile.skills.filter((_, index) => index !== indexToRemove),
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleSave = () => {
    setIsEditing(false);

    const profileData = {
      ...profile,
      skills: profile.skills.filter((skill) => skill.trim() !== ""), // Eliminar cadenas vacías
    };

    if (profile.id) {
      UserProfileApiClient.updateUserProfile(profile.id, profileData)
        .then(() => {
          alert("Profile updated successfully!");
        })
        .catch((error) => {
          console.error("Error updating profile:", error);
          alert("There was an error updating your profile.");
        });
    } else {
      UserProfileApiClient.createUserProfile(profileData)
        .then((newProfile) => {
          setProfile({ ...profile, id: newProfile.id });
          alert("Profile created successfully!");
        })
        .catch((error) => {
          console.error("Error creating profile:", error);
          alert("There was an error creating your profile.");
        });
    }
  };

  const handleDeleteAccount = () => {
    setIsModalOpen(true);
  };

  const confirmDeleteAccount = () => {
    UserProfileApiClient.deleteUserProfile(profile.user_id)
      .then(() => {
        alert("Account deleted successfully.");
        // Aquí podrías redirigir al usuario a otra página si lo deseas
        setIsModalOpen(false);
      })
      .catch((error) => {
        console.error("Error deleting account:", error);
        alert("There was an error deleting your account.");
        setIsModalOpen(false);
      });
  };

  const handleImageUpload = (e, imageType) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setProfile({ ...profile, [imageType]: reader.result });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const toggleLike = () => {
    setHasLiked(!hasLiked);
    setLikeCount((prevCount) => (hasLiked ? prevCount - 1 : prevCount + 1));
  };

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
    // Aquí podrías abrir un chat real o mostrar una ventana de chat
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-50 to-indigo-100 p-4">
      <div className="bg-white rounded-xl shadow-xl p-6 w-full max-w-lg">
        <div className="relative">
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            id="profileImageInput"
            onChange={(e) => handleImageUpload(e, 'profile_image')}
          />
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            id="backgroundImageInput"
            onChange={(e) => handleImageUpload(e, 'background_image')}
          />
          <img
            src={profile.background_image || whiteBackgroundImage}
            alt="Landing"
            className="w-full h-40 object-cover rounded-lg shadow-md cursor-pointer"
            onClick={() => document.getElementById('backgroundImageInput').click()}
          />
          <img
            src={profile.profile_image || whiteBackgroundImage}
            alt="Profile"
            className="absolute top-28 left-1/2 transform -translate-x-1/2 w-28 h-28 rounded-full border-4 border-white shadow-lg cursor-pointer"
            onClick={() => document.getElementById('profileImageInput').click()}
          />
        </div>
        <div className="text-center mt-20">
          <h2 className="text-3xl font-bold text-gray-800">{isEditing ? (
            <input
              type="text"
              name="full_name"
              value={profile.full_name}
              onChange={handleInputChange}
              className="w-full px-2 py-1 border rounded-md focus:outline-none"
            />
          ) : profile.full_name}</h2>
          <p className="text-gray-500 text-sm">Last visit: {profile.last_login}</p>
          <div className="flex justify-center items-center mt-4">
            {[...Array(5)].map((_, index) => (
              <StarIcon
                key={index}
                className={`h-6 w-6 ${index < profile.user_stars ? 'text-yellow-400' : 'text-gray-300'}`}
              />
            ))}
          </div>
          <div className="mt-4 flex items-center justify-center space-x-4">
            <button
              className={`${
                hasLiked ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
              } text-white font-semibold py-2 px-4 rounded-full flex items-center transition duration-300`}
              onClick={toggleLike}
            >
              {hasLiked ? (
                <>
                  <HandThumbDownIcon className="h-5 w-5 mr-2" />
                  Dislike
                </>
              ) : (
                <>
                  <HandThumbUpIcon className="h-5 w-5 mr-2" />
                  Like
                </>
              )}
            </button>
            <span className="text-gray-700 font-semibold">{likeCount} likes</span>
          </div>
        </div>
        <div className="mt-6">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Personal Information</h3>
          <div className="mb-4">
            <label className="block text-gray-600 text-sm mb-1">Full Name</label>
            {isEditing ? (
              <input
                type="text"
                name="full_name"
                value={profile.full_name}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            ) : (
              <p className="text-gray-900">{profile.full_name}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-600 text-sm mb-1">Email</label>
            {isEditing ? (
              <input
                type="email"
                name="email"
                value={profile.email}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            ) : (
              <p className="text-gray-900">{profile.email}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-600 text-sm mb-1">Phone</label>
            {isEditing ? (
              <input
                type="text"
                name="phone_number"
                value={profile.phone_number}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            ) : (
              <p className="text-gray-900">{profile.phone_number}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-600 text-sm mb-1">Date of Birth</label>
            {isEditing ? (
              <input
                type="date"
                name="date_of_birth"
                value={profile.date_of_birth}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            ) : (
              <p className="text-gray-900">{profile.date_of_birth}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-600 text-sm mb-1">City, State</label>
            {isEditing ? (
              <input
                type="text"
                name="location"
                value={profile.location}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            ) : (
              <p className="text-gray-900">{profile.location}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-600 text-sm mb-1">Country</label>
            {isEditing ? (
              <input
                type="text"
                name="country"
                value={profile.country}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            ) : (
              <p className="text-gray-900">{profile.country}</p>
            )}
          </div>
        </div>
        <div className="mt-6">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Account Security</h3>
          <div className="mb-4">
            <label className="block text-gray-600 text-sm mb-1">Password</label>
            <p className="text-gray-900">******</p>
          </div>
          <div className="mb-4">
            <label className="block text-gray-600 text-sm mb-1">Two-Factor Authentication</label>
            <p className="text-gray-900">Enabled</p>
          </div>
          <div className="mb-4">
            <label className="block text-gray-600 text-sm mb-1">Last Login</label>
            <p className="text-gray-900">{profile.last_login}</p>
          </div>
        </div>
        <div className="mt-6">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Notification Preferences</h3>
          <div className="mb-4">
            <label className="block text-gray-600 text-sm mb-1">Notification Frequency</label>
            {isEditing ? (
              <input
                type="text"
                name="notification_email"
                value={profile.notification_email ? 'Enabled' : 'Disabled'}
                onChange={(e) => setProfile({ ...profile, notification_email: e.target.value === 'Enabled' })}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            ) : (
              <p className="text-gray-900">{profile.notification_email ? 'Enabled' : 'Disabled'}</p>
            )}
          </div>
        </div>
        <div className="mt-6">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Skills</h3>
          <div className="flex flex-wrap gap-2 mb-4">
            {profile.skills.map((skill, index) => (
              <div key={index} className="bg-indigo-100 text-indigo-700 px-3 py-1 rounded-full flex items-center">
                {skill}
                {isEditing && (
                  <XMarkIcon
                    className="h-4 w-4 ml-2 cursor-pointer text-red-500"
                    onClick={() => handleRemoveSkill(index)}
                  />
                )}
              </div>
            ))}
          </div>
          {isEditing && (
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleAddSkill}
              placeholder="Add a skill and press Enter"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-400"
            />
          )}
        </div>
        <div className="flex justify-between mt-8">
          {isEditing ? (
            <>
              <button
                className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg flex items-center transition duration-300"
                onClick={handleSave}
              >
                <CheckCircleIcon className="h-5 w-5 mr-2" />
                Save
              </button>
              <button
                className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-lg flex items-center transition duration-300"
                onClick={handleDeleteAccount}
              >
                <TrashIcon className="h-5 w-5 mr-2" />
                Delete Account
              </button>
            </>
          ) : (
            <>
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg flex items-center transition duration-300"
                onClick={() => setIsEditing(true)}
              >
                <PencilSquareIcon className="h-5 w-5 mr-2" />
                Edit Profile
              </button>

              <button
                className="bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded-lg flex items-center transition duration-300"
                onClick={toggleChat}
              >
                <ChatBubbleLeftIcon className="h-5 w-5 mr-2" />
                Chat
              </button>
            </>
          )}
        </div>
      </div>
      {/* Modal para confirmación de eliminación de cuenta */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-lg font-bold mb-4">Are you sure?</h2>
        <p className="mb-4">Do you really want to delete your account? This process cannot be undone.</p>
        <div className="flex justify-end">
          <button
            className="bg-gray-300 hover:bg-gray-400 text-black font-semibold py-2 px-4 rounded-lg mr-2"
            onClick={() => setIsModalOpen(false)}
          >
            No
          </button>
          <button
            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-lg"
            onClick={confirmDeleteAccount}
          >
            Yes, Delete
          </button>
        </div>
      </Modal>
      {/* Placeholder para la ventana de chat */}
      {isChatOpen && (
        <div className="fixed bottom-4 right-4 bg-white shadow-lg rounded-lg p-4 w-64">
          <h3 className="text-lg font-bold mb-2">Chat with {profile.full_name}</h3>
          <p className="text-gray-600">Chat functionality coming soon...</p>
          <button
            className="mt-4 bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300"
            onClick={toggleChat}
          >
            Close Chat
          </button>
        </div>
      )}
    </div>
  );
};

export default Profile;