import React, { useState } from "react";
import ApiAxios from '../../utils/ApiClient/PostApiClient'; // Asegúrate de importar correctamente

function CrearCard() {
  const [title, setTitle] = useState("");
  const [skills, setSkills] = useState("");
  const [salary, setSalary] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setSuccess(null);
    const postData = {
      title,
      skills,
      salary: parseFloat(salary),
      description,
      active: true
    };

    try {
      const response = await ApiAxios.createPost(postData);
      console.log("Card creada:", response);
      setSuccess("Publicación creada con éxito.");
      // Limpia los campos del formulario
      setTitle("");
      setSkills("");
      setSalary("");
      setDescription("");
    } catch (error) {
      console.error("Error al crear la publicación:", error);
      setError("Hubo un error al crear la publicación. Inténtalo de nuevo.");
    }
  };

  return (
    <div className="max-w-md mx-auto mt-5 px-4">
      <h2 className="text-xl font-bold mb-4 text-center">Crear Nueva Card</h2>
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        {error && <div className="text-red-500 text-center mb-4">{error}</div>}
        {success && <div className="text-green-500 text-center mb-4">{success}</div>}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
            Título
          </label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Ingresa el título"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="skills">
            Habilidades
          </label>
          <input
            type="text"
            id="skills"
            value={skills}
            onChange={(e) => setSkills(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Ingresa habilidades separadas por comas (e.g., Excel, PowerPoint, ChatGPT)"
          />
        </div>
        <div className="mb-4">
  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="salary">
    Salario
  </label>
  <input
    type="text"
    id="salary"
    value={salary ? `$${salary}` : ""}
    onChange={(e) => {
      // Eliminar cualquier símbolo de dólar o caracteres no numéricos antes de guardarlo como número
      const cleanSalary = e.target.value.replace(/[^0-9]/g, '');
      setSalary(cleanSalary);
    }}
    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    placeholder="Ingresa el salario"
  />
</div>

        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
            Descripción
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Ingresa una descripción detallada"
          ></textarea>
        </div>
        <div className="flex items-center justify-center">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Publicar
          </button>
        </div>
      </form>
    </div>
  );
}

export default CrearCard;