import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ApiAxios from "../../utils/ApiClient/UserApiClient";

function LogoutUser() {
  const navigate = useNavigate();

  useEffect(() => {
    const logout = async () => {
      try {
        const response = await ApiAxios.logoutUser();
        // Redirigir al usuario a la página de inicio de sesión o a la página principal
        console.log("Cerraste sesión:", response);
        navigate("/");
      } catch (error) {
        console.error("Error al cerrar sesión:", error);
      }
    };

    logout();
  }, [navigate]);

  return (
    <div className="text-center mt-5">
      <h2 className="text-xl font-bold">Cerrando sesión...</h2>
    </div>
  );
}

export default LogoutUser;