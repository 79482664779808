import axiosInstance from '../AxiosConfig'; // Importa la configuración de axios

const PostAcceptanceService = {
  // Crear una nueva aceptación de publicación (Create)
  create(postAcceptanceData) {
    console.log('Intentando crear una nueva aceptación de publicación con los siguientes datos:', postAcceptanceData);
    return axiosInstance.post('/post_acceptances', { post_acceptance: postAcceptanceData })
      .then(response => {
        console.log('Aceptación de publicación creada con éxito:', response.data);
        return response.data;
      })
      .catch(error => {
        console.error('Error al crear la aceptación de la publicación:', error.message);
        console.error('Detalles del error:', error.response?.data || 'No additional details available');
        throw error;
      });
  },

  // Obtener todas las aceptaciones de publicaciones (Read All)
  fetchAll() {
    console.log('Solicitando todas las aceptaciones de publicaciones...');
    return axiosInstance.get('/post_acceptances')
      .then(response => {
        console.log('Aceptaciones de publicaciones obtenidas con éxito:', response.data);
        return response.data;
      })
      .catch(error => {
        console.error('Error al obtener las aceptaciones de publicaciones:', error.message);
        console.error('Detalles del error:', error.response?.data || 'No additional details available');
        throw error;
      });
  },

  // Obtener aceptaciones de publicaciones aceptadas (Read Accepted)
  fetchAccepted(postId) {
    const url = `/post_acceptances/mostrar_aceptados/${postId}`;
    console.log(`Solicitando aceptaciones para la publicación con ID ${postId}...`);
    return axiosInstance.get(url)
      .then(response => {
        console.log('Aceptaciones obtenidas:', response.data);
        return response.data;
      })
      .catch(error => {
        console.error(`Error al obtener las aceptaciones de la publicación con ID ${postId}:`, error.message);
        console.error('Detalles del error:', error.response?.data || 'No se proporcionaron detalles adicionales.');
        throw error;
      });
  },

  // Obtener una aceptación de publicación específica por ID (Read One)
  fetchById(postAcceptanceId) {
    console.log(`Solicitando aceptación de publicación con ID ${postAcceptanceId}...`);
    return axiosInstance.get(`/post_acceptances/${postAcceptanceId}`)
      .then(response => {
        console.log(`Aceptación de publicación con ID ${postAcceptanceId} obtenida con éxito:`, response.data);
        return response.data;
      })
      .catch(error => {
        console.error(`Error al obtener la aceptación de la publicación con ID ${postAcceptanceId}:`, error.message);
        console.error('Detalles del error:', error.response?.data || 'No additional details available');
        throw error;
      });
  },

  // Actualizar una aceptación de publicación existente (Update)
  update(postAcceptanceId, updatedData) {
    console.log(`Intentando actualizar la aceptación de publicación con ID ${postAcceptanceId} con los siguientes datos:`, updatedData);
    return axiosInstance.put(`/post_acceptances/${postAcceptanceId}`, { post_acceptance: updatedData })
      .then(response => {
        console.log(`Aceptación de publicación con ID ${postAcceptanceId} actualizada con éxito:`, response.data);
        return response.data;
      })
      .catch(error => {
        console.error(`Error al actualizar la aceptación de la publicación con ID ${postAcceptanceId}:`, error.message);
        console.error('Detalles del error:', error.response?.data || 'No additional details available');
        throw error;
      });
  },

  // Eliminar una aceptación de publicación (Delete)
  delete(postAcceptanceId) {
    console.log(`Intentando eliminar la aceptación de publicación con ID ${postAcceptanceId}...`);
    return axiosInstance.delete(`/post_acceptances/${postAcceptanceId}`)
      .then(response => {
        console.log(`Aceptación de publicación con ID ${postAcceptanceId} eliminada con éxito:`, response.data);
        return response.data;
      })
      .catch(error => {
        console.error(`Error al eliminar la aceptación de la publicación con ID ${postAcceptanceId}:`, error.message);
        console.error('Detalles del error:', error.response?.data || 'No additional details available');
        throw error;
      });
  },

  getAllPostApplied() {
    console.log("Intentando obtener todos los IDs de publicaciones a las que el usuario ha aplicado...");
  
    return axiosInstance.get("/post_acceptances/getAllPostApplied")
      .then(response => {
        console.log("Post IDs obtenidos con éxito:", response.data.post_ids);
        return response.data.post_ids; // Devuelve solo los IDs de las publicaciones
      })
      .catch(error => {
        console.error("Error al obtener los IDs de las publicaciones aplicadas:", error.message);
  
        if (error.response) {
          console.error("Código de estado HTTP:", error.response.status);
          console.error("Detalles del error:", error.response.data || "No se proporcionaron detalles adicionales.");
        } else {
          console.error("No se recibió una respuesta del servidor:", error);
        }
  
        throw error; // Lanza el error para que pueda ser manejado por el código que llama a esta función
      });
  }

};
window.PostAcceptanceService = PostAcceptanceService;
export default PostAcceptanceService;