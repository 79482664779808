import UserProfileApiClient from '../../utils/ApiClient/UserProfileApiClient';
import paginatedMessages from '../../utils/ApiClient/MessageChat';
import actionCableMessages from '../../utils/ActionCable/ActionCableMessages';

// Genera un ID único para el chatroom basado en los IDs de los usuarios
export const generateChatroomId = (currentUserId, userReceptorId) => {
  return [currentUserId, userReceptorId].sort((a, b) => a - b).join('');
};

// Obtiene los perfiles de usuario, excluyendo al usuario actual
export const fetchUsersProfiles = async (currentUser) => {
  try {
    console.log('Fetching user profiles...');
    const response = await UserProfileApiClient.fetchAllUserProfiles();
    console.log('Profiles fetched successfully.');
    return response;
  } catch (error) {
    console.error('Error fetching user profiles:', error);
    throw error;
  }
};

// Maneja la selección de un usuario, carga mensajes y actualiza el chatroom
export const handleUserSelect = async (user, currentUser, setChatroomId, setSelectedUserName, setAllMessages) => {
  setSelectedUserName(user.full_name);
  console.log(`Usuario seleccionado: ${user.full_name}`);

  const newChatroomId = generateChatroomId(currentUser.id, user.id);
  console.log('Chatroom ID generado:', newChatroomId);

  setChatroomId(newChatroomId);
  setAllMessages([]); // Limpiar mensajes actuales antes de cargar nuevos

  try {
    const initialMessages = await paginatedMessages.get_paginated_messages(newChatroomId, 1);
    console.log('Initial messages loaded.');
    setAllMessages(initialMessages);
  } catch (error) {
    console.error('Error al cargar mensajes:', error);
  }
};

// Maneja el envío de un mensaje
export const handleSendMessage = async (message, currentUser, selectedUserId, chatroomId) => {
  if (!message.trim()) {
    console.warn('No se puede enviar un mensaje vacío.');
    return;
  }

  const messageData = {
    body: message,
    sender_id: currentUser.id,
    recipient_id: selectedUserId,
    chatroom_id: chatroomId,
  };

  try {
    console.log('Sending message...');
    await paginatedMessages.sendMessage(messageData);
    console.log('Message sent:', messageData);
  } catch (error) {
    console.error('Error al enviar el mensaje:', error);
  }
};

// Carga más mensajes al alcanzar el tope del scroll y mantiene la posición del scroll
export const handleScroll = async (chatroomId, page, isFetchingMore, setIsFetchingMore, setAllMessages, setPage, messagesContainerRef) => {
  const container = messagesContainerRef.current;

  if (container.scrollTop === 0 && !isFetchingMore) {
    console.log('Cargando más mensajes...');
    setIsFetchingMore(true);

    try {
      const newMessages = await paginatedMessages.get_paginated_messages(chatroomId, page + 1);
      console.log('Nuevos mensajes recibidos:', newMessages);
      setAllMessages((prevMessages) => [...newMessages, ...prevMessages]);
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error('Error al cargar más mensajes:', error);
    } finally {
      setIsFetchingMore(false);
    }
  }
};

// Carga mensajes paginados
export const loadMessages = async (chatroomId, page, setMessages) => {
  try {
    console.log('Cargando mensajes paginados...');
    const initialMessages = await paginatedMessages.get_paginated_messages(chatroomId, page);
    setMessages((prevMessages) => [...initialMessages, ...prevMessages]);
    console.log('Mensajes cargados.');
  } catch (error) {
    console.error('Error al obtener mensajes:', error);
  }
};

// Suscribe a los mensajes en tiempo real de un chatroom
export const subscribeToMessages = (chatroomId, handleNewMessage) => {
  console.log(`Subscribing to chatroom: ${chatroomId}`);
  actionCableMessages.subscribeToMessagesChannel(chatroomId, handleNewMessage);
};

// Desuscribe de los mensajes en tiempo real de un chatroom
export const unsubscribeFromMessages = (chatroomId) => {
  console.log(`Unsubscribing from chatroom: ${chatroomId}`);
  actionCableMessages.unsubscribeFromMessagesChannel(chatroomId);
};

// Función para desplazar el scroll completamente hacia abajo de forma suave
export const scrollToBottom = (messagesContainerRef) => {
  if (messagesContainerRef.current) {
    const container = messagesContainerRef.current;
    container.scrollTo({
      top: container.scrollHeight, // Asegurar el scroll al final
      behavior: 'smooth', // Desplazamiento suave
    });
    console.log('Desplazado el scroll al fondo.');
  }
};

// Enviar un mensaje con los datos desde el contexto
export const sendMessage = async (messageBody, currentUser, chatroomId, recipientId, setMessageBody) => {
  if (!messageBody.trim()) {
    console.warn('No se puede enviar un mensaje vacío.');
    return;
  }

  const messageData = {
    body: messageBody,
    sender_id: currentUser.id,
    recipient_id: recipientId,
    chatroom_id: chatroomId,
  };

  try {
    console.log('Sending message...');
    await paginatedMessages.sendMessage(messageData);
    setMessageBody(''); // Limpiar el mensaje en el contexto
    console.log('Message sent successfully.');
  } catch (error) {
    console.error('Error al enviar el mensaje:', error);
  }
};

// Mantiene la posición del scroll al cargar más mensajes
export const maintainScrollOnPagination = (messagesContainerRef, prevScrollHeight) => {
  if (messagesContainerRef.current) {
    const container = messagesContainerRef.current;
    const currentScrollHeight = container.scrollHeight;
    container.scrollTop += currentScrollHeight - prevScrollHeight.current; // Ajustar la posición del scroll
    console.log('Manteniendo la posición del scroll después de cargar más mensajes.');
  }
};