import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AcceptedRequestsCard from '../../components/AcceptedRequestsList';
import PostAcceptanceApiClient from '../../utils/ApiClient/PostAcceptanceApiClient';
import UserProfileService from '../../utils/ApiClient/UserProfileApiClient';

function MostrarAceptados() {
  const { postId } = useParams();
  const [acceptedRequests, setAcceptedRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAcceptedRequests = async () => {
      try {
        const data = await PostAcceptanceApiClient.fetchAccepted(postId);

        if (data.length === 0) {
          setError('No se encontraron solicitudes aceptadas.');
          return;
        }

        const detailedRequests = await Promise.all(
          data.map(async (request) => {
            const { message, user_id, subject } = request;

            try {
              const userProfile = await UserProfileService.fetchUserProfileById(user_id);

              if (!userProfile) {
                console.log(`No se encontró el perfil del usuario con ID: ${user_id}`);
                return null;
              }

              const { user_stars, profile_image, full_name, skills } = userProfile;

              return {
                message,
                user_id,
                user_stars,
                profile_image,
                full_name,
                skills,
                subject,
                verified: userProfile.user_verificated || false,
              };
            } catch (error) {
              console.error(`Error al obtener el perfil del usuario con ID: ${user_id}`, error);
              return null;
            }
          })
        );

        setAcceptedRequests(detailedRequests.filter((request) => request !== null));
      } catch (err) {
        setError('Error al obtener las solicitudes aceptadas.');
      } finally {
        setLoading(false);
      }
    };

    fetchAcceptedRequests();
  }, [postId]);

  if (loading) return <p>Cargando datos...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="flex flex-col h-screen p-4 md:p-0 space-y-4">
      {acceptedRequests.length > 0 ? (
        acceptedRequests.map((request) => {
          console.log("Rendering AcceptedRequestsCard with key:", request.user_id);
          return (
            <AcceptedRequestsCard
              key={request.user_id}
              name={request.full_name}
              message={request.message}
              verified={request.verified}
              rating={request.user_stars}
              profileImage={request.profile_image}
              subject={request.subject}
              skills={request.skills}
              userId={request.user_id}
            />
          );
        })
      ) : (
        <p>No se encontraron solicitudes aceptadas.</p>
      )}
    </div>
  );
}

export default MostrarAceptados;