import axiosInstance from '../AxiosConfig'; // Importa la configuración de axios

const PostService = {
  user_posts() {
    console.log('Intentando obtener las publicaciones del usuario actual...');
    
    return axiosInstance.get('/posts/user_posts')  // Cambiado a GET porque está obteniendo datos, no creando
      .then(response => {
        console.log('Publicaciones del usuario obtenidas con éxito:', response.data);
        return response.data;
      })
      .catch(error => {
        console.error('Error al obtener las publicaciones del usuario:', error.message);
        
        if (error.response) {
          console.error('Código de estado HTTP:', error.response.status);
          console.error('Detalles del error:', error.response.data || 'No se proporcionaron detalles adicionales.');
        } else {
          console.error('No se recibió una respuesta del servidor:', error);
        }
  
        throw error;  // Lanzar el error para que pueda ser manejado por el código que llama a esta función
      });
  },
  // Crear una nueva publicación (Create)
  createPost(postData) {
    return axiosInstance.post('/posts', { post: postData })
      .then(response => response.data)
      .catch(error => {
        console.error('Error al crear la publicación:', error);
        throw error;
      });
  },
// Obtener todas las publicaciones (Read All) con paginación
async fetchPostsByPage(page = 1) {
  try {
    const response = await axiosInstance.get(`/posts?page=${page}`);
    console.log('estos son tus posts...');
    return response.data;
  } catch (error) {
    if (error.response) {
      // El servidor respondió con un estado que está fuera del rango 2xx
      console.warn('Error en la respuesta del servidor:', error.response.status, error.response.data);
    } else if (error.request) {
      // La solicitud fue hecha pero no se recibió ninguna respuesta
      console.warn('Error en la solicitud: No se recibió respuesta del servidor.');
    } else {
      // Algo pasó al configurar la solicitud que desencadenó un error
      console.warn('Error al configurar la solicitud:', error.message);
    }
    throw error;  // Lanzar el error para que el componente que llama lo maneje
  }
},



  // Obtener una publicación específica por ID (Read One)
  fetchPostById(postId) {
    return axiosInstance.get(`/posts/${postId}`)
      .then(response => response.data)
      .catch(error => {
        console.error(`Error al obtener la publicación con ID ${postId}:`, error);
        throw error;
      });
  },

  // Actualizar una publicación existente (Update)
  updatePost(postId, updatedData) {
    return axiosInstance.put(`/posts/${postId}`, { post: updatedData })
      .then(response => response.data)
      .catch(error => {
        console.error('Error al actualizar la publicación:', error);
        throw error;
      });
  },

  // Eliminar una publicación (Delete)
  deletePost(postId) {
    return axiosInstance.delete(`/posts/${postId}`)
      .then(response => response.data)
      .catch(error => {
        console.error('Error al eliminar la publicación:', error);
        throw error;
      });
  },

  // Obtener publicaciones del usuario actual (Get User Posts)
  fetchUserPosts() {
    return axiosInstance.get('/posts/user_posts')
      .then(response => {
        console.log('Publicaciones del usuario recibidas:', response.data);
        return response.data;
      })
      .catch(error => {
        console.error('Error al obtener las publicaciones del usuario:', error);
        throw error;
      });
  },
};
window.PostService = PostService;
// Exporta PostService para usar en otras partes de la aplicación
export default PostService;