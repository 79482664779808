import React, { useState } from "react";
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import UserRegister from "../../utils/ApiClient/UserApiClient";
import { useNavigate } from "react-router-dom";

function RegisterUser() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validación para que el correo electrónico sea válido
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setMessage("Por favor, introduce un correo electrónico válido.");
      setMessageType("error");
      return;
    }

    if (password !== passwordConfirmation) {
      setMessage("Las contraseñas no coinciden");
      setMessageType("error");
      return;
    }

    const userData = { email, password, password_confirmation: passwordConfirmation };

    try {
      const response = await UserRegister.registerUser(userData);

      if (response && response.status.code === 200) {
        setMessage("Registro exitoso. Redirigiendo a la página de inicio de sesión...");
        setMessageType("success");
        setTimeout(() => {
          navigate('/login_user');
        }, 2000);
      } else {
        setMessage("Hubo un error al registrar el usuario. Por favor, intenta nuevamente.");
        setMessageType("error");
      }
    } catch (error) {
      setMessage("Hubo un error en el registro. Por favor, intenta nuevamente.");
      setMessageType("error");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h1 className="text-2xl font-bold text-center mb-6">Registro de Usuario</h1>
        {message && (
          <div className={`mb-4 text-center p-2 rounded ${messageType === "success" ? "bg-green-200 text-green-700" : "bg-red-200 text-red-700"}`}>
            {message}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Correo Electrónico:</label>
            <input 
              type="email" 
              id="email" 
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              placeholder="Introduce tu correo" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4 relative">
            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">Contraseña:</label>
            <input 
              type={showPassword ? 'text' : 'password'} 
              id="password" 
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              placeholder="Introduce tu contraseña" 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button 
              type="button" 
              className="absolute right-3 top-2 text-gray-500 focus:outline-none" 
              onClick={() => setShowPassword(!showPassword)} 
            >
              {showPassword ? (
                <EyeSlashIcon className="h-5 w-5" />
              ) : (
                <EyeIcon className="h-5 w-5" />
              )}
            </button>
          </div>
          <div className="mb-6 relative">
            <label htmlFor="passwordConfirmation" className="block text-gray-700 text-sm font-bold mb-2">Confirmación de Contraseña:</label>
            <input 
              type={showPasswordConfirmation ? 'text' : 'password'} 
              id="passwordConfirmation" 
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              placeholder="Confirma tu contraseña" 
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              required
            />
            <button 
              type="button" 
              className="absolute right-3 top-2 text-gray-500 focus:outline-none" 
              onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
            >
              {showPasswordConfirmation ? (
                <EyeSlashIcon className="h-5 w-5" />
              ) : (
                <EyeIcon className="h-5 w-5" />
              )}
            </button>
          </div>
          <div className="flex items-center justify-between">
            <button 
              type="submit" 
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Registrarse
            </button>
            <button
              type="button"
              onClick={() => navigate('/login_user')}
              className="text-blue-500 hover:underline focus:outline-none"
            >
              ¿Ya tienes una cuenta? Inicia sesión
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegisterUser;
