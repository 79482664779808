import React, { useState, useEffect, useContext } from 'react';
import Modal from '../components/ModalWindow';
import UserNotification from '../utils/ApiClient/UserNotificationMailerClient';
import { AuthContext } from '../utils/AuthContext';

const UserProfileCard = ({
  name = "N/A",
  jobTitle = "Developer",
  rating = 0,
  reviews = 0,
  profileImage = "https://via.placeholder.com/100",
  skills = [],
  message = "",
  userId // Se recibe el userId como prop para identificar al usuario
}) => {
    const { currentUser } = useContext(AuthContext);
  const currentUserId = currentUser.id; // Obtener el ID del usuario actual desde el contexto

  const [isHired, setIsHired] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [isTextTruncated, setIsTextTruncated] = useState(true);
  const [existingNotificationId, setExistingNotificationId] = useState(null);

  useEffect(() => {
    const checkIfHired = async () => {
      try {
        console.log("Fetching all notifications...");
        const allNotifications = await UserNotification.obtener_all();
        console.log("All notifications:", allNotifications.data);

        const existingNotification = allNotifications.data.find(
          notif =>
            notif.receptor_id === userId &&
            notif.emisor_id === currentUserId &&
            notif.notification_type === 'hire'
        );

        if (existingNotification) {
          console.log("Existing hire notification found:", existingNotification);
          setIsHired(true);
          setExistingNotificationId(existingNotification.id);
        } else {
          console.log("No existing hire notification found for user ID:", userId);
          setIsHired(false);
        }
      } catch (error) {
        console.error("Error checking if user is hired:", error);
      }
    };

    checkIfHired();
  }, [userId, currentUserId]);

  const handleHireMeClick = () => {
    console.log("Hire Me button clicked. Current isHired state:", isHired);
    if (!isHired) {
      setIsModalOpen(true);
    } else {
      handleDismiss();
    }
  };

  const handleDismiss = async () => {
    if (existingNotificationId) {
      try {
        console.log("Dismissing notification with ID:", existingNotificationId);
        await UserNotification.deleteNotification(existingNotificationId);
        setIsHired(false);
        setExistingNotificationId(null);
        console.log("Notification dismissed for user ID:", userId);
      } catch (error) {
        console.error('Error al eliminar la notificación:', error);
        setErrorMessage('Error al eliminar la notificación. Por favor, inténtalo de nuevo.');
        setIsErrorModalOpen(true);
      }
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setNotificationMessage('');
  };

  const handleErrorModalClose = () => {
    setIsErrorModalOpen(false);
    setErrorMessage('');
  };

  const handleSendNotification = async () => {
    try {
      console.log("Sending notification for user ID:", userId);
      const response = await UserNotification.createNotification({
        receptor_id: userId,
        emisor_id: currentUserId,
        message: notificationMessage,
        read: false,
        notification_type: 'hire',
        url: '/some-url'
      });

      console.log("Notification sent successfully. Response data:", response.data);
      setIsHired(true);
      setExistingNotificationId(response.data.id);
      handleModalClose();
    } catch (error) {
      console.error('Error al crear la notificación:', error);
      setErrorMessage('Error al enviar la notificación. Por favor, inténtalo de nuevo.');
      setIsErrorModalOpen(true);
    }
  };

  const shortText = message && message.length > 100 ? message.slice(0, 100) + '...' : message;

  return (
    <div className="bg-white rounded-lg shadow-md p-4 w-full max-w-lg mx-auto mb-4 relative">
      <div className="absolute top-2 right-2 bg-yellow-500 text-white px-2 py-1 text-xs rounded">
        Top Rated
      </div>

      <div className="flex items-center mb-4">
        <img
          className="w-16 h-16 rounded-full mr-4"
          src={profileImage}
          alt="User Avatar"
        />
        <div>
          <p className="text-lg font-bold text-gray-800">{name}</p>
          <p className="text-sm text-gray-600">{jobTitle}</p>
          <div className="flex items-center mt-1">
            <span className="text-yellow-500 text-sm mr-2">{rating} ★★★★★</span>
            <span className="text-sm text-gray-500">({reviews} reviews)</span>
          </div>
        </div>
      </div>

      {skills.length > 0 && (
        <div className="mb-4">
          <p className="text-sm font-semibold text-gray-800">Skills</p>
          <div className="flex flex-wrap gap-2 mt-2">
            {skills.slice(0, 14).map((skill, index) => (
              <span key={index} className="bg-gray-200 text-gray-800 text-xs font-semibold px-2 py-1 rounded">
                {skill}
              </span>
            ))}
          </div>
        </div>
      )}

      {message && (
        <div className="mb-4">
          <p className="text-sm text-gray-600">
            {isTextTruncated ? shortText : message}
            {message.length > 100 && (
              <button onClick={() => setIsTextTruncated(!isTextTruncated)} className="text-blue-500 hover:underline ml-1">
                {isTextTruncated ? "Ver más" : "Ver menos"}
              </button>
            )}
          </p>
        </div>
      )}

      <div className="flex justify-between items-center">
        <button className="bg-gray-700 text-white px-4 py-2 rounded-md shadow-md hover:bg-gray-800 transition duration-300">
          Ver Perfil
        </button>

        <button
          onClick={handleHireMeClick}
          className={`px-4 py-2 rounded-md text-white shadow-md transition duration-300 ${
            isHired ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'
          }`}
        >
          {isHired ? 'Dismiss' : 'Hire Me'}
        </button>
      </div>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={handleModalClose}>
          <h2 className="text-xl font-bold mb-4">Enviar Notificación</h2>
          <textarea
            className="w-full p-2 border border-gray-300 rounded mb-4"
            placeholder="Escribe tu mensaje..."
            value={notificationMessage}
            onChange={(e) => {
              setNotificationMessage(e.target.value);
            }}
          ></textarea>
          <div className="flex justify-end space-x-2">
            <button
              className="bg-gray-500 text-white py-2 px-4 rounded"
              onClick={handleModalClose}
            >
              Cancelar
            </button>
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded"
              onClick={handleSendNotification}
            >
              Enviar
            </button>
          </div>
        </Modal>
      )}

      {isErrorModalOpen && (
        <Modal isOpen={isErrorModalOpen} onClose={handleErrorModalClose}>
          <h2 className="text-xl font-bold mb-4">Error</h2>
          <p className="text-red-500">{errorMessage}</p>
          <div className="flex justify-end space-x-2">
            <button
              className="bg-red-500 text-white py-2 px-4 rounded"
              onClick={handleErrorModalClose}
            >
              Cerrar
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default UserProfileCard;