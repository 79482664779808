// CableConnection.js
import { createConsumer } from '@rails/actioncable';
const baseURL = process.env.REACT_APP_WEBSOCKET_URL
class CableService {
  constructor() {
    this.consumer = null; // Inicialmente no hay conexión WebSocket
    // Se obtiene la URL del WebSocket desde las variables de entorno
    this.url = process.env.REACT_APP_WEBSOCKET_URL;
  }

  // Inicializar la conexión WebSocket
  initialize() {
    console.log(`Running in ${process.env.REACT_APP_WEBSOCKET_URL} mode. Using API base URL: ${baseURL}`);
    if (this.consumer) {
      console.log('[ActionCable] WebSocket connection already established');
      return this.consumer;
    }

    try {
      // Crear la conexión usando la URL configurada en la variable de entorno
      this.consumer = createConsumer(this.url);
      console.log('[ActionCable] WebSocket connection established:', this.url);
    } catch (error) {
      console.error('[ActionCable] Failed to initialize WebSocket connection:', error);
    }

    return this.consumer;
  }

  // Desconectar el WebSocket si está conectado
  disconnect() {
    if (!this.consumer) {
      console.warn('[ActionCable] No WebSocket connection to close');
      return;
    }

    try {
      this.consumer.disconnect();
      this.consumer = null;
      console.log('[ActionCable] WebSocket connection closed');
    } catch (error) {
      console.error('[ActionCable] Error while disconnecting WebSocket:', error);
    }
  }

  // Obtener la conexión WebSocket activa
  getConsumer() {
    if (!this.consumer) {
      console.warn('[ActionCable] WebSocket connection is not initialized. Call initialize() first.');
      return null;
    }
    return this.consumer;
  }

  // Reconectar cerrando la conexión actual primero
  reconnect() {
    this.disconnect();
    return this.initialize();
  }
}

// Exportamos una única instancia para mantener un Singleton
const cableService = new CableService();
export default cableService;