import React, { useState, useEffect } from 'react';
import CardUser from '../../components/UserPostCard'; // Ajusta la ruta según corresponda
import PostApiClient from '../../utils/ApiClient/PostApiClient';

const UserPosts = () => {
  const [userPosts, setUserPosts] = useState([]);

  useEffect(() => {
    const fetchUserPosts = async () => {
      try {
        const posts = await PostApiClient.fetchUserPosts();
        setUserPosts(posts);
      } catch (error) {
        console.error('Error al obtener las publicaciones del usuario:', error);
      }
    };

    fetchUserPosts();
  }, []);

  const handleDelete = (postId) => {
    setUserPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
  };

  return (
    <div>
      {userPosts.map((post) => (
        <CardUser
          key={post.id}
          name={post.name}
          email={post.email}
          role={post.role}
          title={post.title}
          skills={post.skills}
          salary={post.salary}
          description={post.description}
          postId={post.id}
          onDelete={handleDelete}
          onSave={(updatedPost) => {
            setUserPosts((prevPosts) =>
              prevPosts.map((post) =>
                post.id === updatedPost.postId ? updatedPost : post
              )
            );
          }}
        />
      ))}
    </div>
  );
};

export default UserPosts;