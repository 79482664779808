import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PostAcceptanceApiClient from '../utils/ApiClient/PostAcceptanceApiClient';
import PostApiClient from '../utils/ApiClient/PostApiClient';
import { InboxIcon } from '@heroicons/react/24/solid';

const CardUser = ({
  name: initialName,
  email: initialEmail,
  role: initialRole,
  title: initialTitle,
  skills: initialSkills,
  salary: initialSalary,
  description: initialDescription,
  postId,
  onSave,
  onDelete // Se agrega esta prop para manejar la eliminación desde el componente padre
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(initialName);
  const [email, setEmail] = useState(initialEmail);
  const [role, setRole] = useState(initialRole);
  const [title, setTitle] = useState(initialTitle);
  const [skills, setSkills] = useState(initialSkills);
  const [salary, setSalary] = useState(initialSalary);
  const [description, setDescription] = useState(initialDescription);
  const [acceptanceCount, setAcceptanceCount] = useState(0);

  useEffect(() => {
    const fetchAcceptanceCount = async () => {
      try {
        const acceptedRequests = await PostAcceptanceApiClient.fetchAccepted(postId);
        setAcceptanceCount(acceptedRequests.length);
      } catch (error) {
        console.error('Error al obtener la cantidad de aceptaciones:', error);
      }
    };

    if (postId) {
      fetchAcceptanceCount();
    }
  }, [postId]);

  const handleSave = () => {
    onSave({
      name,
      email,
      role,
      title,
      skills,
      salary,
      description,
      postId,
    });
    setIsEditing(false);
  };

  const handlePostApiClientDelete = async () => {
    try {
      const response = await PostApiClient.deletePost(postId);
      console.log('Post eliminado con éxito:', response);
      if (onDelete) onDelete(postId);
    } catch (error) {
      console.error('Error al eliminar el post:', error);
    }
  };

  return (
    <div className="relative bg-white rounded-lg shadow-md p-6 w-full max-w-md mx-auto mb-4">
      <div className="absolute top-2 right-2 flex items-center space-x-2">
        <InboxIcon className="h-6 w-6 text-blue-500" />
        <span className="text-sm text-gray-700">{acceptanceCount}</span>
      </div>
      <div>
        {isEditing ? (
          <input
            className="text-2xl font-bold text-gray-800 mb-2 w-full border border-gray-300 rounded p-2"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        ) : (
          <h1 className="text-2xl font-bold text-gray-800 mb-2">{title}</h1>
        )}
        {isEditing ? (
          <input
            className="text-xl text-gray-700 mb-2 w-full border border-gray-300 rounded p-2"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        ) : (
          <h2 className="text-xl text-gray-700 mb-2">{name}</h2>
        )}
        {isEditing ? (
          <input
            className="text-gray-600 mb-1 w-full border border-gray-300 rounded p-2"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        ) : (
          <p className="text-gray-600 mb-1">Email: {email}</p>
        )}
        {isEditing ? (
          <input
            className="text-gray-600 mb-1 w-full border border-gray-300 rounded p-2"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          />
        ) : (
          <p className="text-gray-600 mb-1">Rol: {role}</p>
        )}
        {isEditing ? (
          <input
            className="text-gray-600 mb-1 w-full border border-gray-300 rounded p-2"
            value={salary}
            onChange={(e) => setSalary(e.target.value)}
          />
        ) : (
          <p className="text-gray-600 mb-1">Salario: {salary}</p>
        )}
        {isEditing ? (
          <textarea
            className="text-gray-600 mb-2 w-full border border-gray-300 rounded p-2"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        ) : (
          <p className="text-gray-600 mb-2">Descripción: {description}</p>
        )}
        <h3 className="text-lg font-semibold text-gray-700">Habilidades:</h3>
        {skills ? (
          <ul className="list-disc list-inside text-gray-600">
            {skills.split(', ').map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600">No skills provided</p>
        )}
      </div>
      <div className="flex justify-end space-x-4 mt-4">
        <Link
          to={postId ? `/mostrar_aceptados/${postId}` : '/user_posts'}
          className="bg-blue-500 text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 transition duration-300"
        >
          Ver Solicitudes
        </Link>
        {isEditing ? (
          <button
            onClick={handleSave}
            className="bg-green-500 text-white px-4 py-2 rounded-md shadow hover:bg-green-600 transition duration-300"
          >
            Guardar
          </button>
        ) : (
          <button
            onClick={() => setIsEditing(true)}
            className="bg-yellow-500 text-white px-4 py-2 rounded-md shadow hover:bg-yellow-600 transition duration-300"
          >
            Editar
          </button>
        )}
        <button
          onClick={handlePostApiClientDelete}
          className="bg-red-500 text-white px-4 py-2 rounded-md shadow hover:bg-red-600 transition duration-300 focus:outline-none"
        >
          Eliminar
        </button>
      </div>
    </div>
  );
};

export default CardUser;