//src/utils/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import UserService from './ApiClient/UserApiClient';

// Crear el contexto de autenticación
export const AuthContext = createContext();

// Crear el contexto del usuario seleccionado en el chat
export const SelectedUserContext = createContext();

// Crear el contexto del chatroom seleccionado
export const SelectedChatRoomContext = createContext(); 

//#region AuthProvider - Manejo del usuario autenticado
export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    currentUser: null,
    loading: true,
  });

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const storedUser = localStorage.getItem('currentUser');
        if (storedUser) {
          setAuthState({
            currentUser: JSON.parse(storedUser),
            loading: false,
          });
        } else {
          const userInfo = await UserService.getInfoCurrentUser();
          if (userInfo && userInfo.status.code === 200) {
            updateCurrentUser(userInfo.user);
          } else {
            clearAuthState();
          }
        }
      } catch (error) {
        console.log('Error al obtener la información del usuario:', error);
        clearAuthState();
      }
    };

    fetchCurrentUser();
  }, []);

  const clearAuthState = () => {
    setAuthState({
      currentUser: null,
      loading: false,
    });
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('currentUser');
  };

  const updateCurrentUser = (userData) => {
    const userToStore = {
      id: userData.id,
      email: userData.email,
      createdAt: userData.created_at,
      active: userData.active,
      loggedIn: userData.logged_in,
      isAuthenticated: true,
    };

    localStorage.setItem('currentUser', JSON.stringify(userToStore));

    setAuthState({
      currentUser: userToStore,
      loading: false,
    });
  };

  const logout = async () => {
    try {
      await UserService.logoutUser();
      clearAuthState();
      window.location.href = '/'; // Redirige al home después de cerrar sesión
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ ...authState, updateCurrentUser, logout }}>
      {!authState.loading && children}
    </AuthContext.Provider>
  );
};
//#endregion AuthProvider

// Proveedor de usuario seleccionado
export const SelectedUserProvider = ({ children }) => {
  const [selectedUserName, setSelectedUserName] = useState('');

  return (
    <SelectedUserContext.Provider value={{ selectedUserName, setSelectedUserName }}>
      {children}
    </SelectedUserContext.Provider>
  );
};

// Proveedor del chatroom seleccionado
export const SelectedChatRoomProvider = ({ children }) => {
  const [chatroomId, setChatroomId] = useState('');

  return (
    <SelectedChatRoomContext.Provider value={{ chatroomId, setChatroomId }}>
      {children}
    </SelectedChatRoomContext.Provider>
  );
};

// Creando el contexto del perfil de usuario
export const UserProfileContext = createContext(null);

// Proveedor del perfil de usuario
export const UserProfileProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState(null); // Inicializado como null

  return (
    <UserProfileContext.Provider value={{ userProfile, setUserProfile }}>
      {children}
    </UserProfileContext.Provider>
  );
};

// Contexto para el perfil del usuario actual
export const CurrentUserProfileContext = createContext(null);

export const CurrentUserProfileProvider = ({ children }) => {
  const [currentUserProfile, setCurrentUserProfile] = useState(null);

  return (
    <CurrentUserProfileContext.Provider value={{ currentUserProfile, setCurrentUserProfile }}>
      {children}
    </CurrentUserProfileContext.Provider>
  );
};


// Crear el contexto para los cuerpos de los mensajes
export const MessageBodyContext = createContext();

// Proveedor del contexto para los cuerpos de los mensajes
export const MessageBodyProvider = ({ children }) => {
  const [messageBodies, setMessageBodies] = useState([]);

  // Función para agregar un nuevo mensaje al estado
  const addMessageBody = (newMessage) => {
    setMessageBodies((prevMessages) => [...prevMessages, newMessage]);
  };

  return (
    <MessageBodyContext.Provider value={{ messageBodies, addMessageBody }}>
      {children}
    </MessageBodyContext.Provider>
  );
};