import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css'; // Ajusta el camino si es necesario
import App from './App';
import reportWebVitals from './reportWebVitals';
//import { AuthProvider } from './utils/AuthContext'; // Asegúrate de que la ruta es correcta

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);

reportWebVitals(console.log);