import React, { useEffect, useState, useContext } from 'react';
import PostApiClient from '../utils/ApiClient/PostApiClient';
import PostAcceptanceApiClient from '../utils/ApiClient/PostAcceptanceApiClient';
import { AuthContext } from '../utils/AuthContext';
import ModalMessage from './ModalMessage';
import { PencilSquareIcon, CodeBracketIcon, CheckCircleIcon } from '@heroicons/react/24/solid';

const PostCard = ({ title, date, status, skills, salary, postId, postOwnerId, description }) => {
  const [isApplied, setIsApplied] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        const userPosts = await PostApiClient.user_posts();
        const userIsOwner = userPosts.some(post => post.id === postId || postOwnerId === currentUser.id);
        setIsOwner(userIsOwner);

        const applications = await PostAcceptanceApiClient.fetchAll();
        const hasApplied = applications.some(application => application.post_id === postId && application.user_id === currentUser.id);
        setIsApplied(hasApplied);
      } catch (error) {
        console.error('Error al obtener datos del post o la aplicación:', error);
      }
    };

    fetchPostData();
  }, [postId, currentUser.id, postOwnerId]);

  const handleApply = async (subject, message) => {
    try {
      await PostAcceptanceApiClient.create({ post_id: postId, subject, message });
      setIsApplied(true);
      setIsModalOpen(false); // Cierra el modal después de aplicar
    } catch (error) {
      console.error('Error al aplicar a la publicación:', error);
    }
  };

  const handleRemoveApplication = async () => {
    try {
      const applications = await PostAcceptanceApiClient.fetchAll();
      const application = applications.find(app => app.post_id === postId && app.user_id === currentUser.id);

      if (application) {
        await PostAcceptanceApiClient.delete(application.id);
        setIsApplied(false);
      }
    } catch (error) {
      console.error('Error al quitar la aplicación:', error);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-md transition-transform transform hover:scale-105 hover:shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <div>
          <h1 className="text-lg font-bold text-gray-900">{title}</h1>
          <p className="text-sm text-gray-500">{date}</p>
        </div>
        <div className={`px-3 py-1 rounded-full ${status === 'Activo' ? 'bg-green-100 text-green-600' : 'bg-gray-100 text-gray-500'}`}>
          <CheckCircleIcon className="h-5 w-5 inline-block mr-1" />
          {status}
        </div>
      </div>

      <div className="text-sm mb-4">
        <h2 className="font-semibold text-gray-700">Descripción:</h2>
        <p className="text-gray-600">{description}</p>
      </div>

      <div className="text-sm mb-4">
        <h2 className="font-semibold text-gray-700">Habilidades:</h2>
        <ul className="list-disc list-inside text-gray-600">
          {skills.map((skill, index) => (
            <li key={index}>
              <CodeBracketIcon className="h-4 w-4 inline-block mr-1" />
              {skill}
            </li>
          ))}
        </ul>
      </div>

      <div className="text-lg font-semibold text-gray-800 mb-4">Salario: {salary}</div>

      <div className="bg-gray-100 rounded-lg p-4 flex items-center justify-between">
        {!isOwner && !isApplied && (
          <button
            onClick={() => setIsModalOpen(true)}
            className="w-full px-4 py-2 rounded-lg font-semibold bg-yellow-400 text-white hover:bg-yellow-500 transition-all flex items-center justify-center"
          >
            <PencilSquareIcon className="h-5 w-5 mr-2" />
            Aplicar
          </button>
        )}
        
        {!isOwner && isApplied && (
          <button
            onClick={handleRemoveApplication}
            className="w-full px-4 py-2 rounded-lg font-semibold bg-red-500 text-white hover:bg-red-600 transition-all flex items-center justify-center"
          >
            <PencilSquareIcon className="h-5 w-5 mr-2" />
            Quitar aplicación
          </button>
        )}

        {isOwner && (
          <span className="text-gray-500">No puedes aplicar a tu propia publicación</span>
        )}
      </div>

      {isModalOpen && (
        <ModalMessage
          onClose={() => setIsModalOpen(false)}
          onContinue={handleApply} 
        />
      )}
    </div>
  );
};

export default PostCard;  