// AxiosConfig.js
import axios from 'axios';

// Obtiene la URL base de la API desde las variables de entorno
const baseURL = process.env.REACT_APP_API_URL;

console.log(`Running in ${process.env.REACT_APP_ENV} mode. Using API base URL: ${baseURL}`);

const axiosInstance = axios.create({
  baseURL: baseURL, // Usa la URL de la API correspondiente al entorno
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000,
  withCredentials: true, // Permite enviar cookies con las solicitudes
});

// Interceptor para agregar el token JWT en cada solicitud
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;