import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../utils/AuthContext';
import { useNavigate } from 'react-router-dom';
import { PencilSquareIcon, CodeBracketIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import landingpageImage from '../assets/Images/newpexelsimage.jpg';

const Home = () => {
  const { currentUser, loading } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      console.log("Usuario activo:", currentUser.active);
      console.log("ID de usuario:", currentUser.id);
      console.log("Usuario autenticado:", currentUser.email);
      console.log("Usuario logeado:", currentUser.loggedIn);
    } else {
      console.log("No hay usuario autenticado.");
    }
  }, [currentUser]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  const exampleCards = [
    {
      title: "Diseño de Logo",
      description: "Necesito un diseñador gráfico para crear un logo profesional para mi nueva empresa.",
      salary: "$200 USD",
      icon: <PencilSquareIcon className="h-6 w-6 text-blue-500" />
    },
    {
      title: "Desarrollo Web",
      description: "Busco un desarrollador para crear una landing page moderna y responsiva.",
      salary: "$500 USD",
      icon: <CodeBracketIcon className="h-6 w-6 text-blue-500" />
    },
    {
      title: "Redacción de Contenidos",
      description: "Requiero un redactor para escribir artículos de blog",
      salary: "$150 USD",
      icon: <CheckCircleIcon className="h-6 w-6 text-blue-500" />
    },
  ];

  const handleApplyClick = () => {
    navigate('/register_user');
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      {/* Sección de Presentación con Imagen de Fondo */}
      <div
        className="w-full relative text-white text-center bg-cover bg-center min-h-[45vh]"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${landingpageImage})`,
          backgroundPosition: 'center top', // Se ajusta la imagen para estar más arriba
          backgroundSize: 'cover',
        }}
      >
        <div className="flex flex-col items-center justify-center h-full p-8">
          <h1 className="text-5xl md:text-6xl font-bold mb-4">Bienvenido a Trabalu.com</h1>
          <p className="text-xl md:text-2xl mb-6">La plataforma definitiva para gestionar, agendar y delegar tareas con facilidad.</p>
          <div className="flex justify-center gap-4">
            <a
              href="/register_user"
              className="px-8 py-3 bg-blue-500 hover:bg-blue-700 text-white font-semibold rounded-lg transition duration-300"
            >
              Registrar
            </a>
            <a
              href="/"
              className="px-8 py-3 bg-transparent border border-white hover:bg-white hover:text-blue-700 text-white font-semibold rounded-lg transition duration-300"
            >
              Ver Planes
            </a>
          </div>
        </div>
      </div>

      {/* Sección de Tarjetas de Ejemplo */}
      <div className="w-full max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12">
        <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">¿Qué hace Trabalu.com?</h2>
        <p className="text-lg text-gray-700 mb-10 text-center max-w-3xl mx-auto">
          Nuestra aplicación permite a los usuarios crear, agendar y delegar tareas con facilidad. Diseñada para optimizar el manejo del tiempo y la eficiencia, esta plataforma es ideal para personas con múltiples responsabilidades.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {exampleCards.map((card, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow p-6">
              <div className="flex items-center mb-4">
                {card.icon}
                <h3 className="text-xl font-semibold text-gray-800 ml-3">{card.title}</h3>
              </div>
              <p className="text-gray-600 mb-6">{card.description}</p>
              <div className="flex justify-between items-center">
                <span className="text-lg font-semibold text-blue-500">{card.salary}</span>
                <button
                  onClick={handleApplyClick}
                  className="px-4 py-2 rounded-lg font-semibold transition-colors bg-blue-500 text-white hover:bg-blue-700"
                >
                  Aplicar
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default Home;