// import React, { useContext, useEffect, useState, useRef } from 'react';
// import { BellIcon, CheckIcon, TrashIcon } from '@heroicons/react/24/solid'; // Heroicons import
// import { AuthContext } from '../utils/AuthContext';
// import {
//   subscribe,
//   sendSimulatedNotification,
//   getNotifications,
//   markAllAsRead,
//   unsubscribe,
//   markAsRead,
// } from '../utils/sendNotifications'; // Ajusta el path según tu estructura de archivos

// const Testings = () => {
//   const { currentUser } = useContext(AuthContext);
//   const [notifications, setNotifications] = useState([]);
//   const [notificationHistory, setNotificationHistory] = useState([]);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const notificationDropdownRef = useRef(null);

//   useEffect(() => {
//     if (currentUser) {
//       subscribe(currentUser, setNotifications, setNotificationHistory);
//       return () => unsubscribe();
//     }
//   }, [currentUser]);

//   // Toggle para el dropdown de notificaciones
//   const toggleDropdown = () => {
//     setIsDropdownOpen((prev) => !prev);
//   };

//   // Cerrar dropdown al hacer clic fuera
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (notificationDropdownRef.current && !notificationDropdownRef.current.contains(event.target)) {
//         setIsDropdownOpen(false);
//       }
//     };
//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, []);

//   // Marcar como leído y actualizar en tiempo real
//   const handleMarkAsRead = (notificationId) => {
//     markAsRead(notificationId); // Llama a la función que marca como leída en el backend

//     // Actualiza el estado en el frontend para reflejar el cambio en tiempo real
//     setNotifications((prevNotifications) =>
//       prevNotifications.filter((notification) => notification.id !== notificationId)
//     );
//     setNotificationHistory((prevHistory) =>
//       prevHistory.map((notification) =>
//         notification.id === notificationId ? { ...notification, read: true } : notification
//       )
//     );
//   };

//   return (
//     <div className="p-8 flex flex-col items-center justify-center min-h-screen">
//       <h1 className="text-3xl font-bold mb-6 text-center">Testing Notifications</h1>

//       {/* Sección de botones y campanita */}
//       <div className="flex flex-col items-center space-y-6">
//         {/* Campanita de notificaciones */}
//         <div className="relative flex items-center justify-center">
//           <button
//             className="relative text-gray-500 hover:text-gray-700 focus:outline-none"
//             onClick={toggleDropdown}
//           >
//             <BellIcon className="h-12 w-12" />
//             {/* Badge con el número de notificaciones no leídas */}
//             {notifications.length > 0 && (
//               <span className="absolute -top-1 -right-2 bg-red-500 text-white text-xs rounded-full h-6 w-6 flex items-center justify-center">
//                 {notifications.length}
//               </span>
//             )}
//           </button>

//           {/* Dropdown de notificaciones */}
//           {isDropdownOpen && (
//             <div
//               ref={notificationDropdownRef}
//               className="absolute right-0 mt-2 w-80 bg-white shadow-lg rounded-md z-50 overflow-hidden"
//             >
//               <div className="px-4 py-2 text-gray-800 font-bold">Nuevas Notificaciones</div>
//               <ul className="divide-y divide-gray-200 max-h-64 overflow-y-auto">
//                 {notifications.length === 0 ? (
//                   <li className="px-4 py-2 text-sm text-gray-600">No new notifications</li>
//                 ) : (
//                   notifications.map((notification, index) => (
//                     <li key={index} className="px-4 py-2 flex items-start space-x-4">
//                       <div className="flex-grow">
//                         <strong>{notification.message}</strong> - {notification.notification_type}
//                         <div>
//                           <a
//                             href={notification.url}
//                             target="_blank"
//                             rel="noopener noreferrer"
//                             className="text-blue-500 hover:underline text-sm"
//                           >
//                             Ver más
//                           </a>
//                         </div>
//                       </div>
//                       <button
//                         onClick={() => handleMarkAsRead(notification.id)} // Llamada a la función al hacer clic
//                         className="text-green-500 hover:text-green-700"
//                       >
//                         <CheckIcon className="h-6 w-6" />
//                       </button>
//                     </li>
//                   ))
//                 )}
//               </ul>
//               <div className="flex justify-between px-4 py-2 bg-gray-100">
//                 <button
//                   onClick={() => markAllAsRead(setNotificationHistory)}
//                   className="text-blue-500 hover:text-blue-700"
//                 >
//                   Mark All as Read
//                 </button>
//                 <button
//                   onClick={() => getNotifications(currentUser, setNotificationHistory)}
//                   className="text-purple-500 hover:text-purple-700"
//                 >
//                   See Read Notifications
//                 </button>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Botón para enviar notificación simulada */}
//         <button
//           onClick={() => sendSimulatedNotification(4, currentUser, 'Este es un nuevo mensaje 2')}
//           className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
//         >
//           Send Simulated Notification
//         </button>
//       </div>

//       {/* Historial de notificaciones leídas */}
//       <div className="mt-10 w-full max-w-xl">
//         <h2 className="text-xl font-bold mb-4 text-center">Historial de Notificaciones Leídas</h2>
//         <ul className="list-disc list-inside max-h-64 overflow-y-auto border border-gray-200 p-4 rounded-lg">
//           {notificationHistory.length === 0 ? (
//             <li className="text-gray-600">No notifications history available.</li>
//           ) : (
//             notificationHistory.map((notification, index) => (
//               <li key={index} className="flex justify-between items-center mb-2">
//                 <div>
//                   <strong>{notification.message}</strong> - {notification.notification_type}
//                   <div>
//                     <a
//                       href={notification.url}
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       className="text-blue-500 hover:underline text-sm"
//                     >
//                       Ver más
//                     </a>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-2">
//                   <span className="text-gray-500 text-sm">
//                     {notification.read ? 'Leído' : 'No leído'}
//                   </span>
//                   <button className="text-red-500 hover:text-red-700">
//                     <TrashIcon className="h-6 w-6" />
//                   </button>
//                 </div>
//               </li>
//             ))
//           )}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Testings;






import React, { useEffect, useState } from 'react';
import chatchannel from '../utils/ActionCable/ActionCableMessages'; // Asegúrate de importar correctamente
import createMessage from '../utils/ApiClient/MessageChat'; // Importa la función para enviar mensajes

const Testings = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState(''); // Estado para el nuevo mensaje
  const chatroomId = 12; // Chatroom ID debe coincidir con el backend

  useEffect(() => {
    console.log(`[Testings] Suscribiéndose al canal del chatroom ${chatroomId}...`);

    // Función que se ejecutará cuando se reciba un nuevo mensaje
    const handleNewMessage = (message) => {
      console.log('Mensaje recibido en tiempo real:', message);
      setMessages((prevMessages) => [...prevMessages, message]); // Añadir el mensaje recibido al estado
    };

    // Suscribirse al canal de mensajes en tiempo real usando chatchannel
    chatchannel.subscribeToMessagesChannel(chatroomId, handleNewMessage);

    // Cleanup function al desmontar el componente para desuscribirse
    return () => {
      console.log(`[Testings] Desuscribiéndose del canal del chatroom ${chatroomId}...`);
      chatchannel.unsubscribeFromMessagesChannel(chatroomId);
    };
  }, [chatroomId]); // Se suscribe cuando el chatroomId cambia o al montar

  // Función para manejar el envío del mensaje
  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (!newMessage.trim()) {
      console.warn('No se puede enviar un mensaje vacío.');
      return;
    }

    const messageData = {
      body: newMessage,
      sender_id: 1, // Ajusta esto con el ID del usuario actual
      recipient_id: 2, // Ajusta según el destinatario
      chatroom_id: chatroomId,
    };

    try {
      // Aquí se envía el mensaje al backend (a través de ActionCable)
      await createMessage.sendMessage(messageData); // Llamada a la función correcta de envío

      // Log del mensaje enviado
      console.log('El mensaje fue enviado con los siguientes datos:', {
        message: { body: messageData.body },
        sender_id: messageData.sender_id,
        recipient_id: messageData.recipient_id,
        chatroom_id: messageData.chatroom_id,
      });

      // Limpiar el input después de enviar
      setNewMessage('');
    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
    }
  };

  // Mostrar el estado de los mensajes recibidos en tiempo real
  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Mensajes en Tiempo Real (Canal {chatroomId})</h1>

      {/* Mostrar mensajes en tiempo real */}
      <div className="border p-4 h-96 overflow-y-auto">
        {messages.length > 0 ? (
          messages.map((msg, index) => (
            <div key={index} className="p-2 bg-gray-200 rounded mb-2">
              <strong>Mensaje {index + 1}:</strong> {msg.body || 'Mensaje recibido en tiempo real'}
            </div>
          ))
        ) : (
          <p>No hay mensajes aún.</p>
        )}
      </div>

      {/* Input para enviar mensajes */}
      <form onSubmit={handleSendMessage} className="mt-4">
        <input
          type="text"
          className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
          placeholder="Escribe tu mensaje aquí..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)} // Actualizar el valor del input
        />
        <button
          type="submit"
          className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 mt-2"
        >
          Enviar Mensaje
        </button>
      </form>
    </div>
  );
};

export default Testings;