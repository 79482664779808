import React, { useState, useContext } from 'react';
import { MicrophoneIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { handleSendMessage } from '../../pages/Chat/logicaChatRealTime'; // Ajuste de la importación
import { AuthContext, UserProfileContext, SelectedChatRoomContext } from '../../utils/AuthContext';

const SeccionInput = () => {
  const [message, setMessage] = useState('');
  const { currentUser } = useContext(AuthContext); // Usuario actual
  const { userProfile } = useContext(UserProfileContext); // Usuario seleccionado
  const { chatroomId } = useContext(SelectedChatRoomContext); // Chatroom seleccionado

  // Verificar que el perfil de usuario esté disponible
  const selectedUserId = userProfile?.id;



  // Función para manejar el envío del mensaje
  const handleSend = async () => {
    if (!message.trim()) {
      console.warn('No se puede enviar un mensaje vacío.');
      return;
    }

    try {
      await handleSendMessage(message, currentUser, selectedUserId, chatroomId);
      console.log('mensaje enviado...');
      setMessage(''); // Limpiar el input después de enviar
    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
    }
  };

  // Función para manejar el envío con la tecla Enter
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Evitar el salto de línea
      handleSend(); // Llamar a la función de enviar mensaje
    }
  };

  return (
    <div className="w-full bg-white border-t border-gray-200 p-4">
      <div className="flex items-center space-x-3">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown} // Añadir el evento onKeyDown para capturar el Enter
          className="w-full p-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-300"
          placeholder="Escribe tu mensaje aquí..."
        />
        <MicrophoneIcon className="w-6 h-6 text-gray-500" />
        <button
          onClick={handleSend} // Llamar a la función de enviar mensaje al hacer clic
          className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
        >
          <PaperAirplaneIcon className="w-5 h-5 transform rotate-90" />
        </button>
      </div>
    </div>
  );
};

export default SeccionInput;
