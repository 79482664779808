import React from 'react';
import SearchBar from '../../components/SearchComponent';
import CardList from '../../components/CardsList';

const Home = () => {
  return (
    <div>
      <SearchBar />
      {/* Container for the full-width button */}
      <CardList />
    </div>
  );
};

export default Home;