
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ResetPasswordClient from '../../utils/ApiClient/ResetPasswordClient'; // Importa la función desde utils

const ResetPassword = () => {
  const { token } = useParams(); // Captura el token de la URL
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    console.log('Enviando formulario de restablecimiento de contraseña');
  
    // Verificación de que las contraseñas coinciden
    if (password !== passwordConfirmation) {
      console.error('Las contraseñas no coinciden.');
      setError('Las contraseñas no coinciden.');
      return;
    }
  
    console.log('Las contraseñas coinciden. Continuando con la solicitud...');
  
    try {
      console.log('Token:', token);
      console.log('Nueva contraseña:', password);
      console.log('Confirmación de la contraseña:', passwordConfirmation);
  
      // Llamada a la API para restablecer la contraseña
      const response = await ResetPasswordClient.updateResetPassword({
        token, // Envía el token de la URL
        password,
        password_confirmation: passwordConfirmation,
      });
  
      console.log('Respuesta de la API:', response);
  
      setMessage('¡Contraseña restablecida exitosamente!');
      setError('');
      navigate('/login_user');
    } catch (error) {
      console.error('Error al restablecer la contraseña:', error);
  
      // Manejo del error en la interfaz
      setError('Ocurrió un error al restablecer la contraseña.');
    }
  };
  

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h2 className="text-3xl font-bold mb-4">Establecer nueva contraseña</h2>

      <form className="w-full max-w-sm" onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Nueva contraseña
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="passwordConfirmation">
            Confirmar nueva contraseña
          </label>
          <input
            type="password"
            id="passwordConfirmation"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}
        {message && <p className="text-green-500 text-xs italic mb-4">{message}</p>}

        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Restablecer contraseña
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
