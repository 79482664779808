import React, { useState, useCallback, useEffect } from 'react';
import BarraLateral from '../../components/ChatComponents/BarraLateral';
import BarraSuperior from '../../components/ChatComponents/BarraSuperior';
import SeccionInput from '../../components/ChatComponents/SeccionInput';
import ContenedorMensajes from '../../components/ChatComponents/ContenedorMensajes';
import { SelectedUserProvider, SelectedChatRoomProvider, UserProfileProvider, CurrentUserProfileProvider, MessageBodyProvider } from '../../utils/AuthContext'; 

const MainChatPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Inicialmente el sidebar está abierto
  const [isMobile, setIsMobile] = useState(false); // Estado para verificar si es móvil

  // Función para verificar el tamaño de la pantalla
  const checkIfMobile = () => {
    setIsMobile(window.innerWidth <= 768); // Si el ancho es menor o igual a 768px, es móvil
  };

  useEffect(() => {
    checkIfMobile(); // Verificar si es móvil al cargar el componente
    window.addEventListener('resize', checkIfMobile); // Escuchar cambios en el tamaño de la ventana

    return () => {
      window.removeEventListener('resize', checkIfMobile); // Limpiar el evento al desmontar
    };
  }, []);

  // Usar useCallback para memoizar funciones y evitar recrearlas en cada render
  const handleSidebarToggle = useCallback(() => {
    setIsSidebarOpen((prevState) => !prevState);
  }, []);

  const handleBackToUsers = useCallback(() => {
    setIsSidebarOpen(true);
  }, []);

  const handleUserSelect = useCallback(() => {
    if (isMobile) {
      setIsSidebarOpen(false); // Solo cerrar el sidebar en modo móvil
    }
  }, [isMobile]);

  return (
    <MessageBodyProvider>
      <CurrentUserProfileProvider>
        <UserProfileProvider>
          <SelectedChatRoomProvider>
            <SelectedUserProvider>
              <div className="flex h-screen overflow-hidden bg-gray-50">
                {/* Barra lateral de usuarios */}
                {isSidebarOpen && (
                  <div className="bg-red-500 w-full md:w-80">
                    <BarraLateral handleUserSelect={handleUserSelect} />
                  </div>
                )}

                {/* Ventana principal del chat */}
                <div className={`flex-1 flex flex-col ${isSidebarOpen ? 'hidden md:flex' : 'w-full'}`}>
                  {/* Barra superior */}
                  <BarraSuperior
                    handleBackToUsers={handleBackToUsers}
                    handleSidebarToggle={handleSidebarToggle}
                  />

                  {/* Contenedor de mensajes */}
                  <div className="flex-1 overflow-y-auto bg-gray-100">
                    <ContenedorMensajes />
                  </div>

                  {/* Input siempre visible pegado en la parte inferior */}
                  <div className="border-t bg-white sticky bottom-0 p-4">
                    <SeccionInput />
                  </div>
                </div>
              </div>
            </SelectedUserProvider>
          </SelectedChatRoomProvider>
        </UserProfileProvider>
      </CurrentUserProfileProvider>
    </MessageBodyProvider>
  );
};

export default MainChatPage;
