import React, { useRef, useContext, useEffect, useState, useMemo, useCallback } from 'react';
import ComponenteMensaje from './ComponenteMensaje';
import {
  SelectedChatRoomContext,
  SelectedUserContext,
} from '../../utils/AuthContext';
import {
  loadMessages,
  subscribeToMessages,
  unsubscribeFromMessages,
  scrollToBottom,
  handleScroll,
} from '../../pages/Chat/logicaChatRealTime';

const ContenedorMensajes = () => {
  const { selectedUserName } = useContext(SelectedUserContext); // Nombre del usuario seleccionado
  const { chatroomId } = useContext(SelectedChatRoomContext); // ID del chatroom

  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMoreMessages, setIsLoadingMoreMessages] = useState(false);

  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const prevScrollHeight = useRef(0);
  const messagesDelay = 1000; // Reducido para mejorar la experiencia de usuario

  // Memorizar mensajes ordenados
  const sortedMessages = useMemo(() => {
    return [...messages].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  }, [messages]);

  useEffect(() => {
    if (selectedUserName) {
      setIsLoading(true);
      setMessages([]);
      setPage(1);

      const timeoutId = setTimeout(() => {
        setIsLoading(false);
        scrollToBottom(messagesContainerRef);
      }, messagesDelay);

      return () => clearTimeout(timeoutId);
    }
  }, [selectedUserName]);

  // Manejador de nuevos mensajes
  const handleNewMessage = useCallback((message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  }, []);

  useEffect(() => {
    if (!chatroomId) return;

    const loadInitialMessages = async () => {
      await loadMessages(chatroomId, 1, setMessages);
      subscribeToMessages(chatroomId, handleNewMessage);
    };

    loadInitialMessages();

    return () => {
      unsubscribeFromMessages(chatroomId);
    };
  }, [chatroomId, handleNewMessage]);

  useEffect(() => {
    if (sortedMessages.length > 0 && !isLoading) {
      scrollToBottom(messagesContainerRef);
    }
  }, [sortedMessages, isLoading]);

  const maintainScrollPosition = useCallback(() => {
    if (messagesContainerRef.current && prevScrollHeight.current !== 0) {
      const currentScrollHeight = messagesContainerRef.current.scrollHeight;
      messagesContainerRef.current.scrollTop += (currentScrollHeight - prevScrollHeight.current);
    }
  }, []);

  useEffect(() => {
    maintainScrollPosition();
  }, [messages, maintainScrollPosition]);

  const onScroll = useCallback(async () => {
    const container = messagesContainerRef.current;

    if (container.scrollTop === 0 && !isLoadingMoreMessages) {
      prevScrollHeight.current = container.scrollHeight;
      setIsLoadingMoreMessages(true);

      try {
        await new Promise((resolve) => setTimeout(resolve, messagesDelay));
        await handleScroll(chatroomId, page, setMessages, setPage);
        maintainScrollPosition();
      } catch (error) {
        console.error('Error al cargar más mensajes:', error);
      } finally {
        setIsLoadingMoreMessages(false);
      }
    }
  }, [chatroomId, page, isLoadingMoreMessages, maintainScrollPosition, messagesDelay]);

  if (!selectedUserName) {
    return (
      <div className="flex-1 flex flex-col h-full">
              <div className="flex justify-center items-center p-4">
              </div>
      </div>
    );
  }

  return (
    <div className="flex-1 flex flex-col h-full">
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <p className="text-gray-500">Cargando mensajes...</p>
        </div>
      ) : (
        <div
          className="flex-1 overflow-y-auto p-6 bg-gray-100"
          ref={messagesContainerRef}
          onScroll={onScroll}
          style={{ maxHeight: '100%', flexGrow: 1 }}
        >
          {sortedMessages.length > 0 ? (
            sortedMessages.map((mensaje, index) => (
              <ComponenteMensaje
                key={index}
                mensaje={mensaje.body}
                sender_id={mensaje.sender_id}
                recipient_id={mensaje.recipient_id}
                created_at={mensaje.created_at}
              />
            ))
          ) : (
            <p>No hay mensajes aún.</p>
          )}
          {isLoadingMoreMessages && (
            <div className="flex justify-center items-center p-4">
              <p className="text-gray-500">Cargando más mensajes...</p>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      )}
    </div>
  );
};

export default ContenedorMensajes;
