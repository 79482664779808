import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { fetchUsersProfiles } from '../../pages/Chat/logicaChatRealTime';
import { AuthContext, SelectedUserContext, SelectedChatRoomContext, UserProfileContext, CurrentUserProfileContext } from '../../utils/AuthContext';

// Función para generar el ID del chatroom concatenando los user_id
const generateChatroomId = (user1Id, user2Id) => {
  return user1Id < user2Id ? `${user1Id}${user2Id}` : `${user2Id}${user1Id}`;
};

const BarraLateral = ({ handleUserSelect }) => {
  // Definir los contextos
  const { currentUser } = useContext(AuthContext);
  const { setSelectedUserName } = useContext(SelectedUserContext);
  const { setChatroomId } = useContext(SelectedChatRoomContext);
  const { setUserProfile } = useContext(UserProfileContext);
  const { setCurrentUserProfile } = useContext(CurrentUserProfileContext);

  const [responseWithoutCurrentUser, setResponseWithoutCurrentUser] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  // Obtener la lista de usuarios excluyendo el currentUser actual
  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        if (currentUser?.id) {
          // Obtener los perfiles de usuario
          const profiles = await fetchUsersProfiles(currentUser);

          // Filtrar el usuario actual (usando user_id)
          const filteredProfiles = profiles.filter(user => user.user_id !== currentUser.id);

          // Guardar los perfiles filtrados en el estado
          setResponseWithoutCurrentUser(filteredProfiles);

          // Guardar el perfil del usuario actual
          const currentUserProfile = profiles.find(user => user.user_id === currentUser.id);
          setCurrentUserProfile(currentUserProfile);
        }
      } catch (error) {
        console.error('Error al obtener los perfiles de usuario:', error);
      }
    };

    fetchProfiles();
  }, [currentUser, setCurrentUserProfile]);

  // Filtrar perfiles basados en el término de búsqueda
  const filteredUserProfiles = useMemo(() => {
    return responseWithoutCurrentUser.filter(user =>
      user.full_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [responseWithoutCurrentUser, searchTerm]);

  // Manejo de la selección de un usuario
  const handleUserClick = useCallback((user) => {
    const newChatroomId = generateChatroomId(currentUser.id, user.user_id);
    setSelectedUserName(user.full_name); // Establecer el nombre del usuario seleccionado
    setUserProfile(user); // Establecer el perfil del usuario en el contexto
    setChatroomId(newChatroomId); // Establecer el chatroomId en el contexto
    handleUserSelect(user.user_id); // Manejar la selección del usuario
  }, [currentUser.id, handleUserSelect, setSelectedUserName, setUserProfile, setChatroomId]);

  return (
    <aside className="w-full md:w-80 bg-white border-r border-gray-200 flex flex-col h-full">
      <div className="flex items-center justify-between p-4 border-b">
        <h2 className="text-lg font-semibold">Usuarios</h2>
      </div>

      {/* Barra de búsqueda */}
      <div className="p-4">
        <div className="flex items-center bg-gray-200 rounded-lg p-2">
          <MagnifyingGlassIcon className="w-5 h-5 text-gray-400" />
          <input
            type="text"
            className="ml-2 bg-transparent outline-none w-full"
            placeholder="Buscar usuarios..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            aria-label="Buscar usuarios"
          />
        </div>
      </div>

      {/* Lista de usuarios */}
      <ul className="flex-1 overflow-y-auto p-4 space-y-4">
        {filteredUserProfiles.length > 0 ? (
          filteredUserProfiles.map((user) => (
            <li key={user.user_id}>
              <button
                className="w-full flex items-center p-3 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => handleUserClick(user)}
              >
                <img
                  src={user.profile_image || 'https://via.placeholder.com/50'} // Imagen predeterminada si no tiene
                  alt={`Foto de perfil de ${user.full_name}`}
                  className="w-10 h-10 rounded-full mr-3"
                />
                <div className="flex-1">
                  <p className="font-semibold text-gray-900">{user.full_name}</p>
                </div>
              </button>
            </li>
          ))
        ) : (
          <p className="text-center text-gray-500">No se encontraron usuarios.</p>
        )}
      </ul>
    </aside>
  );
};

export default React.memo(BarraLateral); // Memoizar el componente para evitar renders innecesarios
