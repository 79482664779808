import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    es: {
      translation: {
        welcome: "Bienvenido a Trabalu",
        logout: "Cerrar sesión",
        // Agrega más traducciones aquí
      }
    },
    // Si decides agregar más idiomas, pero quieres mantener el español
    // en todo momento, no hace falta configurar más recursos aquí
  },
  lng: 'es', // Forzar siempre el idioma español
  fallbackLng: 'es', // Idioma de respaldo siempre español
  interpolation: {
    escapeValue: false, // React ya hace el escape de valores
  },
  detection: {
    // Deshabilitar detección automática del idioma del navegador
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator'],
    caches: [],
  },
});

export default i18n;