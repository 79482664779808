import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'; // Importa los iconos
import userlogin from '../../utils/ApiClient/UserApiClient';
import { AuthContext } from '../../utils/AuthContext';
import ResetPassword from '../../utils/ApiClient/ResetPasswordClient'; // Importa el servicio de restablecimiento

function LoginUser() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para mostrar el modal
  const [resetEmail, setResetEmail] = useState(''); // Correo para el modal
  const [resetMessage, setResetMessage] = useState(''); // Mensaje de éxito o error para el modal
  const [resetMessageType, setResetMessageType] = useState(''); // Tipo de mensaje en el modal (éxito o error)
  const navigate = useNavigate();
  const { updateCurrentUser } = useContext(AuthContext);

  // Enviar solicitud de restablecimiento de contraseña
  const handleSubmitResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await ResetPassword.sendResetPasswordEmail(resetEmail); // Llama al servicio
      setResetMessage('Instrucciones enviadas exitosamente.');
      setResetMessageType('success'); // Mensaje de éxito
    } catch (error) {
      setResetMessage('Error al enviar las instrucciones.');
      setResetMessageType('error'); // Mensaje de error
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage(null); // Limpiar mensajes anteriores

    const userData = { email, password };

    try {
      const response = await userlogin.loginUser(userData);
      console.log('Usuario ha iniciado sesión exitosamente:', response);

      if (response.jwt) {
        localStorage.setItem('jwtToken', response.jwt);
        console.log('JWT almacenado en localStorage:', response.jwt);
      }

      updateCurrentUser(response.user);

      setMessage("Inicio de sesión exitoso. Redirigiendo...");
      setMessageType("success");
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      setMessage("Error al iniciar sesión. Verifica tus credenciales.");
      setMessageType("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">Iniciar Sesión</h1>
        
        {message && (
          <div 
            className={`mb-4 text-center p-2 rounded ${messageType === "success" ? "bg-green-200 text-green-700" : "bg-red-200 text-red-700"}`}
            role="alert"
            aria-live="assertive"
          >
            {message}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Correo Electrónico:</label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              placeholder="Introduce tu correo" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          
          <div className="mb-6 relative">
            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
              Contraseña:
            </label>
            <div className="relative">
              <input 
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                className="shadow appearance-none border rounded w-full py-2 px-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Introduce tu contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeSlashIcon className="h-5 w-5" />
                ) : (
                  <EyeIcon className="h-5 w-5" />
                )}
              </button>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <button 
              type="submit" 
              className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Iniciando...' : 'Iniciar Sesión'}
            </button>
            <button
              type="button"
              onClick={() => setIsModalOpen(true)} // Mostrar modal
              className="text-blue-500 hover:underline focus:outline-none"
            >
              ¿Olvidaste tu contraseña?
            </button>
          </div>
        </form>

        <div className="mt-6 text-center">
          <p className="text-gray-700">¿No tienes una cuenta?</p>
          <button 
            onClick={() => navigate('/register_user')} 
            className="mt-2 text-blue-500 hover:underline focus:outline-none"
          >
            Regístrate aquí
          </button>
        </div>
      </div>

      {/* Modal para restablecimiento de contraseña */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            <h2 className="text-lg font-bold mb-4">Restablecer Contraseña</h2>
            <form onSubmit={handleSubmitResetPassword}>
              <div className="mb-4">
                <label htmlFor="resetEmail" className="block text-gray-700 text-sm font-bold mb-2">
                  Introduce tu correo:
                </label>
                <input 
                  type="email" 
                  id="resetEmail" 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                  placeholder="Correo electrónico"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  required
                />
              </div>
              {resetMessage && (
                <p className={`${resetMessageType === 'success' ? 'text-green-500' : 'text-red-500'}`}>
                  {resetMessage}
                </p>
              )}
              <div className="flex items-center justify-between">
                <button 
                  type="button"
                  onClick={() => setIsModalOpen(false)} // Cerrar el modal
                  className="bg-gray-500 text-white font-bold py-2 px-4 rounded hover:bg-gray-700"
                >
                  Cancelar
                </button>
                <button 
                  type="submit"
                  className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginUser;
