import React from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import Home from './pages/HomePage';
import CrearCard from './pages/Post/CreatePost';
import LoginUser from './pages/User/UserLogin';
import RegisterUser from './pages/User/UserRegister';
import Profile from './pages/User/UserProfile';
import Publicaciones from './pages/Post/PostsPage';
import UserPosts from './pages/User/UserPosts';
import LogoutUser from './pages/User/UserLogout';
import MostrarAceptados from './pages/Acceptances/AcceptedApplications';
import MainChat from './pages/Chat/MainChatPage';
import Testing from './pages/Testing';
import { AuthProvider } from './utils/AuthContext'; // Asegúrate de que la ruta sea correcta
import ResetPassword from './pages/Auth/ResetPassword';
import './i18n'; // Importa la configuración de i18next

function App() {
  return (
    <AuthProvider>
      <Router basename="/">
        <MainLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/publicaciones" element={<Publicaciones />} />
            <Route path="/publicaciones" element={<Publicaciones />} />
            <Route path="/crear_cards" element={<CrearCard />} />
            <Route path="/login_user" element={<LoginUser />} />
            <Route path="/register_user" element={<RegisterUser />} />
            <Route path="/logout_user" element={<LogoutUser />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/user_posts" element={<UserPosts />} />
            <Route path="/mostrar_aceptados/:postId" element={<MostrarAceptados />} />
            <Route path="/main_chat" element={<MainChat />} />
            <Route path="/Testing" element={<Testing />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
          </Routes>
        </MainLayout>
      </Router>
    </AuthProvider>
  );
}

export default App;
