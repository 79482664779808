import axiosInstance from '../AxiosConfig'; // Importa la configuración de axios

const MessageChat = {
  // Obtener mensajes paginados (Read Paginated)
  async get_paginated_messages(chatroomId, page = 1, per_page = 15) {
    console.log(`Solicitando mensajes paginados para chatroom ${chatroomId}, página ${page}...`);
  
    try {
      const response = await axiosInstance.get('/messages/get_paginated_messages', {
        params: {
          chatroom_id: chatroomId,
          page: page,
          per_page: per_page
        }
      });
  
      if (response.data && response.data.messages.length > 0) {
        console.log(`Mensajes obtenidos con éxito (página ${page}):`, response.data.messages);
        return response.data.messages;
      } else {
        console.warn(`No se encontraron más mensajes para chatroom ${chatroomId}, página ${page}.`);
        return [];
      }
    } catch (error) {
      console.error('Error al obtener los mensajes:', error.message);
      console.error('Detalles del error:', error.response?.data || 'No hay detalles adicionales disponibles');
      throw error;
    }
  },

  // Enviar un nuevo mensaje (Create)
  async sendMessage({ body, sender_id, recipient_id, chatroom_id }) {
    // Validar que los datos necesarios están presentes
    if (!body || !sender_id || !recipient_id || !chatroom_id) {
      console.error('Datos faltantes para enviar el mensaje:', { body, sender_id, recipient_id, chatroom_id });
      throw new Error('No se pueden enviar mensajes sin los datos requeridos.');
    }

    const messageData = {
      message: { body },
      sender_id,
      recipient_id,
      chatroom_id
    };

    console.log('Intentando enviar un nuevo mensaje con los siguientes datos:', messageData);

    try {
      // Asegúrate de que la ruta es la correcta para tu backend
      const response = await axiosInstance.post('/messages/create_message', messageData);
      
      // Si el mensaje fue enviado correctamente
      console.log('Mensaje enviado con éxito:', response.data);

      return response.data;
    } catch (error) {
      // Manejo detallado del error
      const errorMsg = error.response?.data || 'No additional details available';
      console.error('Error al enviar el mensaje:', error.message);
      console.error('Detalles del error:', errorMsg);

      throw new Error(`Error al enviar el mensaje: ${errorMsg}`);
    }
  }
};

export default MessageChat;