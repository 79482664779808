import React, { useContext, useEffect, memo } from 'react';
import { UserProfileContext, CurrentUserProfileContext } from '../../utils/AuthContext';

// Componente memoizado para evitar renders innecesarios
const ComponenteMensaje = memo(({ mensaje, sender_id, recipient_id, created_at }) => {
  const { userProfile } = useContext(UserProfileContext); // Acceder al perfil del otro usuario desde el contexto
  const { currentUserProfile } = useContext(CurrentUserProfileContext); // Acceder al perfil del usuario actual desde el nuevo contexto

  const isSender = sender_id === currentUserProfile?.id; // Verificar si el mensaje es del usuario actual

  // Loguear solo una vez si el perfil del otro usuario cambia
  useEffect(() => {
    if (userProfile) {
      console.log('Perfil del otro usuario:', userProfile.profile_image);
    } else {
      console.log('No hay perfil del otro usuario disponible en el contexto');
    }
  }, [userProfile]);

  // Loguear solo una vez si el perfil del usuario actual cambia
  useEffect(() => {
    if (currentUserProfile) {
      console.log('Perfil del usuario actual:', currentUserProfile.profile_image);
    } else {
      console.log('No hay perfil del usuario actual disponible en el contexto');
    }
  }, [currentUserProfile]);

  return (
    <div className={`flex mb-2 ${isSender ? 'justify-end' : 'justify-start'} items-start`}>
      {/* Imagen de perfil si es un mensaje del otro usuario */}
      {!isSender && (
        <img
          src={userProfile?.profile_image || 'https://via.placeholder.com/50'} // Mostrar la imagen del perfil del otro usuario o un placeholder
          alt={userProfile?.full_name || 'Usuario'}
          className="w-8 h-8 rounded-full mr-2"
        />
      )}

      {/* Contenedor del mensaje */}
      <div
        className={`max-w-[80%] px-3 py-2 rounded-lg shadow ${
          isSender ? 'bg-blue-500 text-white' : 'bg-white text-gray-900'
        } text-sm md:text-base`}
      >
        <p>{mensaje}</p>

        {/* Hora del mensaje */}
        <span className="block mt-1 text-xs text-gray-300">{created_at}</span>
      </div>

      {/* Imagen de perfil para el usuario actual */}
      {isSender && (
        <img
          src={currentUserProfile?.profile_image || 'https://via.placeholder.com/50'} // Mostrar la imagen del perfil del usuario actual o un placeholder
          alt="Usuario Actual"
          className="w-8 h-8 rounded-full ml-2"
        />
      )}
    </div>
  );
});

export default ComponenteMensaje;
