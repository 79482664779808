import React, { useState } from 'react';
import searchIcon from '../assets/Images/lupasearch.png'; // Ajusta el path a donde tengas la imagen

const SearchBar = () => {
  const [selectedFilter, setSelectedFilter] = useState(null);

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter === selectedFilter ? null : filter);
  };

  return (
    <div className="w-full mb-5">
      <div className="max-w-xl mx-auto bg-white p-4 rounded-lg shadow-md">
        <div className="flex items-center border-b border-gray-300 pb-2">
          <img src={searchIcon} alt="Buscar" className="h-6 w-6 text-gray-500 mr-2 flex-shrink-0" />  
          <input
            type="text"
            placeholder="Buscar proyecto"
            className="w-full border-none focus:outline-none text-gray-700"
          />
        </div>
        <div className="flex space-x-2 mt-3 overflow-x-auto no-scrollbar">
          <button className="px-4 py-1 border border-gray-400 rounded-md text-gray-600 whitespace-nowrap" onClick={() => handleFilterClick('Salario')}>
            Salario
          </button>
          <button className="px-4 py-1 border border-gray-400 rounded-md text-gray-600 whitespace-nowrap" onClick={() => handleFilterClick('Idioma')}>
            Idioma
          </button>
          <button className="px-4 py-1 border border-gray-400 rounded-md text-gray-600 whitespace-nowrap" onClick={() => handleFilterClick('Herramientas')}>
            Herramientas
          </button>
          <button className="px-4 py-1 border border-gray-400 rounded-md text-gray-600 whitespace-nowrap" onClick={() => handleFilterClick('Sueldo')}>
            Sueldo
          </button>
        </div>
        {selectedFilter && (
          <div className="mt-2 border border-gray-300 rounded-md">
            <ul className="text-gray-700">
              {selectedFilter === 'Salario' && (
                <>
                  <li className="px-4 py-2">Menos de $20,000</li>
                  <li className="px-4 py-2">$20,000 - $50,000</li>
                  <li className="px-4 py-2">Más de $50,000</li>
                </>
              )}
              {selectedFilter === 'Idioma' && (
                <>
                  <li className="px-4 py-2">Español</li>
                  <li className="px-4 py-2">Inglés</li>
                  <li className="px-4 py-2">Francés</li>
                </>
              )}
              {selectedFilter === 'Herramientas' && (
                <>
                  <li className="px-4 py-2">React</li>
                  <li className="px-4 py-2">Angular</li>
                  <li className="px-4 py-2">Vue</li>
                </>
              )}
              {selectedFilter === 'Sueldo' && (
                <>
                  <li className="px-4 py-2">Mensual</li>
                  <li className="px-4 py-2">Quincenal</li>
                  <li className="px-4 py-2">Por proyecto</li>
                </>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;